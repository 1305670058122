import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Table, Dropdown } from 'semantic-ui-react';
import Image from "components/shared/Image";
import { CallsTemplate } from 'components/CallsTemplate';
import FilterIcon from "assets/images/filter_icon.svg";
import SearchIcon from "assets/images/search-icon.svg";
import CrossIcon from "assets/images/filter-close.svg";
import SortIcon from "assets/images/sort-icon.svg";
import SortAsc from "assets/images/sort-asc.svg";
import SortDesc from "assets/images/sort-desc.svg";
import GreenSearchIcon from 'assets/images/greensearch_icon.svg';
import ExpandIcon from "assets/images/calls-expand.svg";
import CollapseIcon from "assets/images/calls-collapse.svg";
import ClosedRequestsIcon from "assets/images/closed-requests.svg";
import WhiteCrossIcon from "assets/images/white-cross.svg";
import { ImageParaSkeleton } from 'components/shared/ImageParaSkeleton';
import DownloadIcon from "assets/images/download-icon.svg";
import { convertTo12HourFormat, calculateTimeDifference, msToTime } from "utils/helpers/helpers";
import { formatDate } from "utils/formatDate/formatDate";
import { getCallsAPIData } from 'redux/actions/calls/calls.actions';
import { selectors as requestCallsSelectors } from 'redux/reducers/calls/calls_reducer';
import { selectors as callsFilterSelectors } from 'redux/reducers/callsFilters/callsFilters_reducer';
import queryString from 'query-string';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getCallsFiltersAPIData } from 'redux/actions/callsFilters/callsFilters.actions';
import { getUserProfilePictures } from "api/getUserProfilePictures";
import { getUserId } from "utils/auth/auth";
import './RequestCalls.scss';

export const RequestCalls = ({ hideCallRec }) => {

    const callsData = [{
        id: 1,
        expertName: 'Leslie Alexander',
        relevantPos: 'Head of Product Design',
        company: 'Biffco Enterprises Ltd.',
        expertTypes: 'Company',
        extNetwork: 'AlphaSights',
        startDate: '22 Feb ‘2024',
        startTime: ' 9:15 AM - 10:15AM IST     (1 Hr)',
        atendees: ['440574', '439746'],
        status: 'Completed'
    },
    {
        id: 2,
        expertName: 'Dianne Russell',
        relevantPos: 'Head of Product Design',
        company: 'Biffco Enterprises Ltd.',
        expertTypes: 'Company',
        extNetwork: 'AlphaSights',
        startDate: '22 Feb ‘2024',
        startTime: ' 9:15 AM - 10:15AM IST     (1 Hr)',
        atendees: ['440574', '439746', '439256', '440527', '352900', '439744'],
        status: 'Upcoming'
    },
    {
        id: 3,
        expertName: 'Bessie Cooper',
        relevantPos: 'Head of Product Design',
        company: 'Biffco Enterprises Ltd.',
        expertTypes: 'Company',
        extNetwork: 'AlphaSights',
        startDate: '22 Feb ‘2024',
        startTime: '-',
        atendees: ['440574', '439746', '439256', '440527'],
        status: 'Requested'
    },
    {
        id: 4,
        expertName: 'Floyd Miles',
        relevantPos: 'Head of Product Design',
        company: 'Biffco Enterprises Ltd.',
        expertTypes: 'Company',
        extNetwork: 'AlphaSights',
        startDate: '22 Feb ‘2024',
        startTime: ' 9:15 AM - 10:15AM IST     (1 Hr)',
        atendees: ['440574', '439746', '439256', '440527'],
        status: 'Cancelled'
    },
    {
        id: 5,
        expertName: 'Leslie Alexander',
        relevantPos: 'Head of Product Design',
        company: 'Biffco Enterprises Ltd.',
        expertTypes: 'Company',
        extNetwork: 'AlphaSights',
        startDate: '22 Feb ‘2024',
        startTime: ' 9:15 AM - 10:15AM IST     (1 Hr)',
        atendees: ['440574', '439746', '439256', '440527'],
        status: 'Completed'
    },
    {
        id: 6,
        expertName: 'Dianne Russell',
        relevantPos: 'Head of Product Design',
        company: 'Biffco Enterprises Ltd.',
        expertTypes: 'Company',
        extNetwork: 'AlphaSights',
        startDate: '22 Feb ‘2024',
        startTime: ' 9:15 AM - 10:15AM IST     (1 Hr)',
        atendees: ['440574', '439746', '439256', '440527'],
        status: 'Upcoming'
    },
    {
        id: 7,
        expertName: 'Bessie Cooper',
        relevantPos: 'Head of Product Design',
        company: 'Biffco Enterprises Ltd.',
        expertTypes: 'Company',
        extNetwork: 'AlphaSights',
        startDate: '22 Feb ‘2024',
        startTime: '-',
        atendees: ['440574', '439256', '440527'],
        status: 'Requested'
    },
    {
        id: 8,
        expertName: 'Floyd Miles',
        relevantPos: 'Head of Product Design',
        company: 'Biffco Enterprises Ltd.',
        expertTypes: 'Company',
        extNetwork: 'AlphaSights',
        startDate: '22 Feb ‘2024',
        startTime: ' 9:15 AM - 10:15AM IST     (1 Hr)',
        atendees: ['440574', '439256', '440527'],
        status: 'Cancelled'
    },
    {
        id: 9,
        expertName: 'Leslie Alexander',
        relevantPos: 'Head of Product Design',
        company: 'Biffco Enterprises Ltd.',
        expertTypes: 'Company',
        extNetwork: 'AlphaSights',
        startDate: '22 Feb ‘2024',
        startTime: ' 9:15 AM - 10:15AM IST     (1 Hr)',
        atendees: ['440574', '439256', '440527'],
        status: 'Completed'
    },
    {
        id: 10,
        expertName: 'Dianne Russell',
        relevantPos: 'Head of Product Design',
        company: 'Biffco Enterprises Ltd.',
        expertTypes: 'Company',
        extNetwork: 'AlphaSights',
        startDate: '22 Feb ‘2024',
        startTime: ' 9:15 AM - 10:15AM IST     (1 Hr)',
        atendees: ['440574', '439746', '439256', '440527', '352900', '439744'],
        status: 'Upcoming'
    },
    {
        id: 11,
        expertName: 'Bessie Cooper',
        relevantPos: 'Head of Product Design',
        company: 'Biffco Enterprises Ltd.',
        expertTypes: 'Company',
        extNetwork: 'AlphaSights',
        startDate: '22 Feb ‘2024',
        startTime: '-',
        atendees: ['440574', '439746', '439256', '440527', '352900', '439744'],
        status: 'Requested'
    },
    {
        id: 12,
        expertName: 'Floyd Miles',
        relevantPos: 'Head of Product Design',
        company: 'Biffco Enterprises Ltd.',
        expertTypes: 'Company',
        extNetwork: 'AlphaSights',
        startDate: '22 Feb ‘2024',
        startTime: ' 9:15 AM - 10:15AM IST     (1 Hr)',
        atendees: ['440574', '439746', '439256', '440527', '352900', '439744'],
        status: 'Cancelled'
    },
    {
        id: 13,
        expertName: 'Leslie Alexander',
        relevantPos: 'Head of Product Design',
        company: 'Biffco Enterprises Ltd.',
        expertTypes: 'Company',
        extNetwork: 'AlphaSights',
        startDate: '22 Feb ‘2024',
        startTime: ' 9:15 AM - 10:15AM IST     (1 Hr)',
        atendees: ['439746', '439256', '352901', '439745', '440576', '439747', '439255', '440528', '352904', '439748'],
        status: 'Completed'
    },
    {
        id: 14,
        expertName: 'Dianne Russell',
        relevantPos: 'Head of Product Design',
        company: 'Biffco Enterprises Ltd.',
        expertTypes: 'Company',
        extNetwork: 'AlphaSights',
        startDate: '22 Feb ‘2024',
        startTime: ' 9:15 AM - 10:15AM IST     (1 Hr)',
        atendees: ['440574', '439746', '439256', '440527', '352900'],
        status: 'Upcoming'
    },
    {
        id: 15,
        expertName: 'Bessie Cooper',
        relevantPos: 'Head of Product Design',
        company: 'Biffco Enterprises Ltd.',
        expertTypes: 'Company',
        extNetwork: 'AlphaSights',
        startDate: '22 Feb ‘2024',
        startTime: '-',
        atendees: ['440574', '439746', '439256', '440527', '352900', '439744'],
        status: 'Requested'
    },
    {
        id: 16,
        expertName: 'Floyd Miles',
        relevantPos: 'Head of Product Design',
        company: 'Biffco Enterprises Ltd.',
        expertTypes: 'Company',
        extNetwork: 'AlphaSights',
        startDate: '22 Feb ‘2024',
        startTime: ' 9:15 AM - 10:15AM IST     (1 Hr)',
        atendees: ['440574', '439746'],
        status: 'Cancelled'
    }
    ]
    const dispatch = useDispatch();
    const callsSelectorData = useSelector(requestCallsSelectors?.getCallsData);
    const noRecFound = useSelector(requestCallsSelectors?.getNoRecordFound);
    const loading = useSelector(requestCallsSelectors?.getLoading);
    const callsFilters = useSelector(callsFilterSelectors?.getCallsFiltersResults);
    const [expandedData, setExpandedData] = useState(null);
    const [total, setTotal] = useState(0);
    const [userTimezone, setUserTimezone] = useState('');
    const [expandedDataResp, setExpandedDataResp] = useState([]);
    const [sortState, setSortState] = useState(null);
    const [relevantCompanySearchTerm, setRelevantCompanySearchTerm] = useState('');
    const queryData = queryString.parse(location.search)?.interviewId;

    const [selectedCheckBoxes, setSelectedCheckBoxes] = useState([]);
    const [data, setData] = useState(callsData);
    const [lastPage, setLastPage] = useState(true);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(0);
    const [clickOutside, setClickOutside] = useState(false);
    const [showCrossIcon, setShowCrossIcon] = useState({
        'expertName': false,
        'expertTypes': false,
        'relevantCompanies': false,
        'networkProviders': false,
        'status': false,
    });
    const dropdownRefs = {
        expertName: useRef(null),
        expertTypes: useRef(null),
        relevantCompanies: useRef(null),
        networkProviders: useRef(null),
        status: useRef(null),
    };
    const [dropdownOpen, setDropdownOpen] = useState({
        expertName: false,
        expertTypes: false,
        relevantCompanies: false,
        networkProviders: false,
        status: false,
    });
    const [expertTypesFilters, setExpertTypesFilters] = useState([]);
    const [relevantCompaniesFilters, setRelevantCompaniesFilters] = useState([]);
    const [expertNameSearchTerm, setExpertNameSearchTerm] = useState('');
    const [relevantCompaniesInitialFilters, setRelevantCompaniesInitialFilters] = useState([]);
    const [networkProvidersFilters, setNetworkProvidersFilters] = useState([]);
    const [statusFilters, setStatusFilters] = useState([]);

    const [filters, setFilters] = useState({
        'expertName': '',
        'expertTypes': [],
        'relevantCompanies': [],
        'status': [],
        'networkProviders': [],
    });
    const [crossIconClick, setCrossIconClick] = useState(false);

    const [openScheduleCall, setOpenScheduleCall] = useState(false);
    const [openExpertProfileFlag, setOpenExpertProfile] = useState({
        open: false,
        selectedIndex: -1,
        selectedRecord: null,
        lastRecord: false
    });
    const [selectedExpert, setSelectedExpert] = useState();
    const isInitialMount = useRef(true);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const getUserTimeZone = async () => {
        try {
            const hrid = await getUserId();
            const resp = await getUserProfilePictures([hrid]);

            if (resp?.length > 0 && resp[0]?.hostOfficeLocation?.timezone) {
                setUserTimezone(resp[0].hostOfficeLocation.timezone);
            }
        } catch (error) {
            console.error('Error fetching user timezone:', error);
            setUserTimezone('Error fetching timezone');
        }
    };

    useEffect(() => {
        getUserTimeZone();
    }, []);

    useEffect(() => {
        dispatch(getCallsFiltersAPIData(queryData));
    }, []);

    useEffect(() => {
        setExpertTypesFilters(callsFilters?.expertTypes);
        setRelevantCompaniesFilters(callsFilters?.companies);
        setRelevantCompaniesInitialFilters(callsFilters?.companies);
        setNetworkProvidersFilters(callsFilters?.networkProviders);
        setStatusFilters(callsFilters?.status);
    }, [callsFilters]);

    useEffect(() => {
        setTotal(callsSelectorData?.totalCalls);
        let templateResp = callsSelectorData?.callDetails;
        if (isInitialMount.current) {
            // Skip the effect on initial mount
            isInitialMount.current = false;
            templateResp = [];
            return;
        }
        let newData = [...data] || [];

        if (!hideCallRec) {
            if (callsSelectorData?.lastPage && callsSelectorData?.firstPage) {
                setData(callsSelectorData?.callDetails);
            }
            else if (!callsSelectorData?.lastPage) {
                if (data?.length > 0 && !callsSelectorData?.firstPage) {
                    for (let i = 0; i < templateResp?.length; i++) {
                        newData?.push(templateResp[i]);
                    }
                    setData([...newData]);
                } else {
                    setData(callsSelectorData?.callDetails);
                }
            } else {
                for (let i = 0; i < templateResp?.length; i++) {
                    newData?.push(templateResp[i]);
                }
                setData([...newData]);
            }
            setLastPage(callsSelectorData?.lastPage);
            if (callsSelectorData?.callDetails?.length === 0) {
                setHasMore(false);
            }
        }
    }, [callsSelectorData, sortState]);

    const handleExpandedState = () => {
        setExpandedData(null);
    }

    const handleDateDisplay = (item) => {
        if (item?.callStatus === 'Requested') {
            return 'NA'
        }
        if (item?.callStatus === 'Completed') {
            const startDate = new Date(item?.callManagementInterviewedSlot?.startDateTime);
            const formattedStartDate = formatDate(startDate.toLocaleDateString());
            return formattedStartDate
        }
        if (item?.callStatus === 'Cancelled') {
            const startDate = new Date(item?.callManagementScheduledSlot?.startDateTime);
            const formattedStartDate = formatDate(startDate.toLocaleDateString());
            return formattedStartDate
        }
        if (item?.callStatus === 'Upcoming') {
            const startDate = new Date(item?.callManagementScheduledSlot?.startDateTime);
            const formattedStartDate = formatDate(startDate.toLocaleDateString());
            return formattedStartDate
        }
    }

    const handleTimeDisplay = (item) => {
        if (item?.callStatus === 'Requested') {
            return 'NA'
        }
        if (item?.callStatus === 'Completed') {
            const startDate = new Date(item?.callManagementInterviewedSlot?.startDateTime);
            const endDate = new Date(item?.callManagementInterviewedSlot?.endDateTime);
            const formattedStartTime = convertTo12HourFormat(msToTime(startDate.getTime()));
            const formattedEndTime = convertTo12HourFormat(msToTime(endDate.getTime()));
            // Get the time difference
            const { diffHours, remainingMinutes } = calculateTimeDifference(formattedStartTime, formattedEndTime);
            const timeDiff = `${diffHours} Hr${diffHours !== 1 ? 's' : ''}${remainingMinutes > 0 ? ` ${remainingMinutes} Min` : ''}`;

            return `${formattedStartTime} - ${formattedEndTime} ${userTimezone} (${timeDiff})`;
        }
        if (item?.callStatus === 'Cancelled') {
            const startDate = new Date(item?.callManagementScheduledSlot?.startDateTime);
            const endDate = new Date(item?.callManagementScheduledSlot?.endDateTime);
            const formattedStartTime = convertTo12HourFormat(msToTime(startDate.getTime()));
            const formattedEndTime = convertTo12HourFormat(msToTime(endDate.getTime()));
            // Get the time difference
            const { diffHours, remainingMinutes } = calculateTimeDifference(formattedStartTime, formattedEndTime);
            const timeDiff = `${diffHours} Hr${diffHours !== 1 ? 's' : ''}${remainingMinutes > 0 ? ` ${remainingMinutes} Min` : ''}`;

            return `${formattedStartTime} - ${formattedEndTime} ${userTimezone} (${timeDiff})`;
        }
        if (item?.callStatus === 'Upcoming') {
            const startDate = new Date(item?.callManagementScheduledSlot?.startDateTime);
            const endDate = new Date(item?.callManagementScheduledSlot?.endDateTime);
            const formattedStartTime = convertTo12HourFormat(msToTime(startDate.getTime()));
            const formattedEndTime = convertTo12HourFormat(msToTime(endDate.getTime()));
            // Get the time difference
            const { diffHours, remainingMinutes } = calculateTimeDifference(formattedStartTime, formattedEndTime);
            const timeDiff = `${diffHours} Hr${diffHours !== 1 ? 's' : ''}${remainingMinutes > 0 ? ` ${remainingMinutes} Min` : ''}`;

            return `${formattedStartTime} - ${formattedEndTime} ${userTimezone} (${timeDiff})`;
        }
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            let isClickInside = false;
            for (const key in dropdownRefs) {
                if (dropdownRefs[key].current && dropdownRefs[key].current.contains(event.target)) {
                    isClickInside = true;
                    break;
                }
            }

            if (!isClickInside || event.target.classList.contains('filtercross--icon') || event.target.classList.contains('header--text') || event.target.classList.contains('header--divText')) {
                setClickOutside(true);
                // setExpertNameSearchTerm(filters['expertName']);
                setDropdownOpen({
                    expertName: false,
                    expertTypes: false,
                    relevantCompanies: false,
                    networkProviders: false,
                    status: false,
                });
                setShowCrossIcon({
                    expertName: false,
                    expertTypes: false,
                    relevantCompanies: false,
                    networkProviders: false,
                    status: false,
                });
                // setCompanySearchTerm('');
                // setCompaniesFilters(expRecFiltersData?.companies);
                // setCountriesFilters(expRecFiltersData?.countries);
                // setCountrySearchTerm('');
            }
            else {
                setClickOutside(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [crossIconClick]);

    const openCallScheduler = () => {
        setOpenScheduleCall(true);
    }
    const handleRowClick = (item) => {
        setExpandedDataResp(item);
        setExpandedData((prevId) => (prevId === item?.id ? null : item?.id));
    }

    const handleStatus = (item) => {
        if (item === 'Completed') {
            return 'completed--status'
        }
        else if (item === 'Upcoming') {
            return 'upcoming--status'
        }
        else if (item === 'Requested') {
            return 'requested--status'
        }
        else if (item === 'Cancelled') {
            return 'cancelled--status'
        }
    }

    const handleCrossIconClick = (type) => {
        setDropdownOpen({
            expertName: false,
            expertTypes: false,
            relevantCompanies: false,
            networkProviders: false,
            status: false,
        });
        setCrossIconClick(true);
        //  setExpertNameSearchTerm(filters['expertName']);
        toggleCrossIcon(type, false);
        // setCompanySearchTerm('');
        // setCountrySearchTerm('');
        // setCompaniesFilters(expRecFiltersData?.companies);
        // setCountriesFilters(expRecFiltersData?.countries);
    };

    const openExpertProfile = (record, index) => {
        setOpenExpertProfile({
            open: true,
            selectedIndex: index,
            selectedRecord: record,
            lastRecord: (index + 1) == data.length
        });
    }
    const handleSchedulePopupClose = () => {
        setOpenScheduleCall(false);
    }
    const handleExpertProfileClose = () => {
        setOpenExpertProfile({
            open: false,
            selectedIndex: -1,
            selectedRecord: null,
            lastRecord: false
        });
    }

    const loadMoreData = () => {
        if (lastPage === false) {
            setPage(prevPage => prevPage + 1);
            fetchData(page + 1);
        }
    };

    useEffect(() => {
        fetchData(page);
    }, [hideCallRec]);

    const fetchData = async (page) => {
        if (!hideCallRec) {
            let templateQueryData = {
                requestId: queryData,
                expertName: filters['expertName']?.length > 0 ? filters['expertName'] : null,
                expertTypes: filters['expertTypes']?.length > 0 ? filters['expertTypes'] : null,
                relevantCompanies: filters['relevantCompanies']?.length > 0 ? filters['relevantCompanies'] : null,
                status: filters['status']?.length > 0 ? filters['status'] : null,
                networkProviders: filters['networkProviders']?.length > 0 ? filters['networkProviders'] : null,
            }
            dispatch(getCallsAPIData(templateQueryData, page, (sortState !== null && (sortState === true ? 'Newest' : 'Oldest'))));
        }
    };

    const handleSort = (flag) => {
        if (loading) {
            return
        }
        setData([]);
        setPage(0);
        setLastPage(true);
        let templateQueryData = {
            requestId: queryData,
            expertName: filters['expertName']?.length > 0 ? filters['expertName'] : null,
            expertTypes: filters['expertTypes']?.length > 0 ? filters['expertTypes'] : null,
            relevantCompanies: filters['relevantCompanies']?.length > 0 ? filters['relevantCompanies'] : null,
            status: filters['status']?.length > 0 ? filters['status'] : null,
            networkProviders: filters['networkProviders']?.length > 0 ? filters['networkProviders'] : null,
        }
        dispatch(getCallsAPIData(templateQueryData, page, flag === true ? 'Newest' : 'Oldest'));
        setSortState(flag);
    };

    const handleCheckBoxChange = (event, inputData) => {
        let prevdata = selectedCheckBoxes; // Initialize prevdata
        let updatedSelectedCheckBoxes;
        if (inputData.checked) {
            updatedSelectedCheckBoxes = [...selectedCheckBoxes, inputData?.label?.templateId]
            setSelectedCheckBoxes(prev => [...prev, inputData?.label?.templateId]);
        } else {
            updatedSelectedCheckBoxes = selectedCheckBoxes.filter(item => item !== inputData?.label?.templateId);
            setSelectedCheckBoxes(prev => prev.filter(item => item !== inputData?.label?.templateId));
        }
    }

    const handleClearFilter = (column) => {
        setData([]);
        setFilters({ ...filters, [column]: [] });
        toggleCrossIcon(column, false);
        setDropdownOpen({
            expertName: false,
            expertTypes: false,
            relevantCompanies: false,
            networkProviders: false,
            status: false,
        });
        let templateQueryData = {
            requestId: queryData,
            expertName: filters['expertName']?.length > 0 ? filters['expertName'] : null,
            expertTypes: filters['expertTypes']?.length > 0 ? filters['expertTypes'] : null,
            networkProviders: filters['networkProviders']?.length > 0 ? filters['networkProviders'] : null,
            relevantCompanies: filters['relevantCompanies']?.length > 0 ? filters['relevantCompanies'] : null,
            status: filters['status']?.length > 0 ? filters['status'] : null,
        }
        if (column === 'expertName') {
            templateQueryData.expertName = '';
            setExpertNameSearchTerm('');
        }
        else if (column === 'expertTypes') {
            templateQueryData.expertTypes = null;
        }
        else if (column === 'networkProviders') {
            templateQueryData.networkProviders = null;
        }
        else if (column === 'relevantCompanies') {
            templateQueryData.relevantCompanies = null;
        }
        else if (column === 'status') {
            templateQueryData.status = null;
        }
        setPage(0);
        // dispatch(getCallsAPIData(templateQueryData, 0, (sortState !== null && (sortState === true ? 'Newest' : 'Oldest'))));
        dispatch(getCallsAPIData(templateQueryData, 0, (sortState !== null && (sortState === true ? 'Newest' : 'Oldest'))));
        toggleCrossIcon(column, false);
    };

    const handleKeyUp = (e) => {
        if (e.keyCode === 32) {
            e.target.value = e.target.value + " "
            e.stopPropagation()
        }
    }

    const searchExpertNameHandler = (expertName) => {
        if (expertName?.length > 2) {
            // if ((searchFieldCallFlag && expertName.length > 2) || !searchFieldCallFlag || resetGridFlag) {
            // updatedFilters = updatedFilters ? updatedFilters : filters;
            if (loading) {
                return
            }
            setData([]);
            setPage(0);
            // !resetGridFlag ? setLastPage(true) : '';
            setFilters({ ...filters, ['expertName']: expertName });
            let templateQueryData = {
                requestId: queryData,
                expertName: expertName,
                relevantCompanies: filters['relevantCompanies']?.length > 0 ? filters['relevantCompanies'] : null,
                expertTypes: filters['expertTypes']?.length > 0 ? filters['expertTypes'] : null,
                networkProviders: filters['networkProviders']?.length > 0 ? filters['networkProviders'] : null,
                status: filters['status']?.length > 0 ? filters['status'] : null,
            }
            dispatch(getCallsAPIData(templateQueryData, 0, (sortState !== null && (sortState === true ? 'Newest' : 'Oldest'))));
        }
    }

    const handleSearchChange = (event, type) => {
        event.stopPropagation();
        const searchTerm = event?.target?.value?.toLowerCase();
        if (type === 'relevantCompanies') {
            setRelevantCompanySearchTerm(event?.target?.value);
            let companiesFiltersTempResult = relevantCompaniesInitialFilters?.filter((item) => {
                return item?.name?.toLowerCase().includes(searchTerm);
            });
            setRelevantCompaniesFilters(companiesFiltersTempResult);
        }
        if (type === 'expertName') {
            setRelevantCompanySearchTerm('');
            setExpertNameSearchTerm(event?.target?.value);
        }
    };

    const uniqueValuesForColumn = (column) => {
        switch (column) {
            case 'companies':
                return relevantCompaniesFilters;
            case 'status':
                return statusFilters;
            case 'expertTypes':
                return expertTypesFilters;
            case 'networkProviders':
                return networkProvidersFilters;
        }
    };

    const handleChangeFilter = (column, value) => {
        setPage(0)
        setData([]);
        setExpandedData(null);
        const newFilters = filters[column]?.includes(value)
            ? filters[column].filter(item => item !== value)
            : [...filters[column], value];

        setFilters({ ...filters, [column]: newFilters });
        let tempFilters = { ...filters, [column]: newFilters }
        // searchExpertNameHandler(expertNameSearchTerm, tempFilters, false, false);

        let templateQueryData = {
            requestId: queryData,
            expertName: tempFilters['expertName']?.length > 0 ? tempFilters['expertName'] : null,
            relevantCompanies: tempFilters['relevantCompanies']?.length > 0 ? tempFilters['relevantCompanies'] : null,
            expertTypes: tempFilters['expertTypes']?.length > 0 ? tempFilters['expertTypes'] : null,
            networkProviders: tempFilters['networkProviders']?.length > 0 ? tempFilters['networkProviders'] : null,
            status: tempFilters['status']?.length > 0 ? tempFilters['status'] : null,
        }
        dispatch(getCallsAPIData(templateQueryData, 0, (sortState !== null && (sortState === true ? 'Newest' : 'Oldest'))));
    }

    const handleSelectAll = (selectAllParam, selFilter) => {
        if (loading) {
            return
        }
        let temparr = [];
        setPage(0);
        switch (selectAllParam) {
            case 'expertTypesAll':
                if (filters['expertTypes'].length === selFilter.length) {
                    temparr = [];
                } else {
                    temparr = selFilter?.map(item => item.name);
                }
                setFilters({ ...filters, ['expertTypes']: temparr });
                let templateQueryExpertTypeData = {
                    requestId: queryData,
                    expertName: filters['expertName']?.length > 0 ? filters['expertName'] : null,
                    relevantCompanies: filters['relevantCompanies']?.length > 0 ? filters['relevantCompanies'] : null,
                    expertTypes: temparr,
                    networkProviders: filters['networkProviders']?.length > 0 ? filters['networkProviders'] : null,
                    status: filters['status']?.length > 0 ? filters['status'] : null,
                }
                dispatch(getCallsAPIData(templateQueryExpertTypeData, 0, (sortState !== null && (sortState === true ? 'Newest' : 'Oldest'))));
                break;

            case 'networkProvidersAll':
                if (filters['networkProviders'].length === selFilter.length) {
                    temparr = [];
                } else {
                    temparr = selFilter?.map(item => item.name);
                }
                setFilters({ ...filters, ['networkProviders']: temparr });
                let templateQueryNetworkData = {
                    requestId: queryData,
                    expertName: filters['expertName']?.length > 0 ? filters['expertName'] : null,
                    relevantCompanies: filters['relevantCompanies']?.length > 0 ? filters['relevantCompanies'] : null,
                    expertTypes: filters['expertTypes']?.length > 0 ? filters['expertTypes'] : null,
                    networkProviders: temparr,
                    status: filters['status']?.length > 0 ? filters['status'] : null,
                }
                dispatch(getCallsAPIData(templateQueryNetworkData, 0, (sortState !== null && (sortState === true ? 'Newest' : 'Oldest'))));
                break;

            case 'relevantCompaniesAll':
                if (filters['relevantCompanies'].length === selFilter.length) {
                    temparr = [];
                } else {
                    temparr = selFilter?.map(item => item.name);
                }
                setFilters({ ...filters, ['relevantCompanies']: temparr });
                let templateQueryCompanyData = {
                    requestId: queryData,
                    expertName: filters['expertName']?.length > 0 ? filters['expertName'] : null,
                    relevantCompanies: temparr,
                    expertTypes: filters['expertTypes']?.length > 0 ? filters['expertTypes'] : null,
                    networkProviders: filters['networkProviders']?.length > 0 ? filters['networkProviders'] : null,
                    status: filters['status']?.length > 0 ? filters['status'] : null,
                }
                dispatch(getCallsAPIData(templateQueryCompanyData, 0, (sortState !== null && (sortState === true ? 'Newest' : 'Oldest'))));
                break;

            case 'statusAll':
                if (filters['status'].length === selFilter.length) {
                    temparr = [];
                } else {
                    temparr = selFilter?.map(item => item.name);
                }
                setFilters({ ...filters, ['status']: temparr });
                let templateQueryStatusData = {
                    requestId: queryData,
                    expertName: filters['expertName']?.length > 0 ? filters['expertName'] : null,
                    relevantCompanies: filters['relevantCompanies']?.length > 0 ? filters['relevantCompanies'] : null,
                    expertTypes: filters['expertTypes']?.length > 0 ? filters['expertTypes'] : null,
                    networkProviders: filters['networkProviders']?.length > 0 ? filters['networkProviders'] : null,
                    status: temparr
                }
                dispatch(getCallsAPIData(templateQueryStatusData, 0, (sortState !== null && (sortState === true ? 'Newest' : 'Oldest'))));
                break;

            default:
                break;
        }
    }


    const toggleCrossIcon = (column, flag) => {
        if (flag) {
            setShowCrossIcon({ ...showCrossIcon, [column]: flag });
        }
        else {
            setShowCrossIcon({ ...showCrossIcon, [column]: false });
        }
    };

    const handleAllCheckChange = () => {
        let temp = [];
        // let tempData = data?.filter(((itemTemp) => itemTemp?.status === 'Awaiting Approval'));
        let tempData = data;
        if (selectedCheckBoxes?.length === tempData?.length) {
            setSelectedCheckBoxes([]);
        }
        else {
            data?.map((item, index) => {
                // if (item?.status === 'Awaiting Approval')

                temp?.push(item?.templateId);
            });
        }

        setSelectedCheckBoxes(temp);
    }

    const handleCarouselActionClicked = (action, selectionIndex) => {
        if (action == 'next') {
            onExpertNameClicked(selectionIndex + 1);
            setOpenExpertProfile({
                open: true,
                selectedIndex: selectionIndex + 1,
                selectedRecord: data[selectionIndex + 1],
                lastRecord: (selectionIndex + 2) == data.length
            });
        } else {
            if (selectionIndex > 0) {
                onExpertNameClicked(selectionIndex - 1);
                setOpenExpertProfile({
                    open: true,
                    selectedIndex: selectionIndex - 1,
                    selectedRecord: data[selectionIndex - 1],
                    lastRecord: (selectionIndex + 2) == data.length
                });
            }
        }
    }

    const handlerRequestCallClick = () => {
        openCallScheduler();
    }

    const onExpertNameClicked = (selectedIndex) => {
        let selectedData = {};
        if (selectedIndex == 1) {
            selectedData = {
                name: 'Oliver Sweet',
                position: 'Senior Procurement',
                company: 'Walmart',
                location: 'Germany',
                type: 'Supplier',
                extNetwork: 'Athenum',
                status: "Approved",
                slotDetails: [{
                    checked: true,
                    date: '20/02/2024',
                    day: 'Tuesday',
                    time: '2:00 - 2:30 PM',
                    timezone: 'CST'
                }]
            };
        } else {
            selectedData = {
                name: 'Hanz Weber',
                position: 'Senior Procurement',
                company: 'Walmart',
                location: 'Italy',
                type: 'Target',
                extNetwork: 'Guidepoint',
                status: "Approved",
                slotDetails: [{
                    checked: true,
                    date: '20/02/2024',
                    day: 'Tuesday',
                    time: '2:00 - 2:30 PM',
                    timezone: 'CST'
                }]
            };
        }
        setSelectedExpert(selectedData);
    }

    return (
        <>
            <div className="requestCalls__wrap">
                <div className='requestCalls__head'>
                    <div className="grid-header-count">
                        <div className='count--badge'>{total || '0'}</div>
                        Calls
                    </div>
                    <div className='download__callWrap'>
                        <Image src={DownloadIcon} className='download--icon' alt="DownloadIcon"
                        // onClick={() => exportToExcel()} 
                        />
                        Download Call Data
                    </div>
                </div>
                {
                    noRecFound === false &&
                    <div className="requestCalls__top-filters">
                        <div className="top-filter-name">Follow Up Calls &nbsp;2</div>
                    </div>
                }
                {
                    // noRecFound === false ?
                    <>
                        <Table sortable celled className='calls--table'>
                            <Table.Header className='completedcalls__header'>
                                <Table.Row>
                                    <Table.HeaderCell className='exp-header--title width-action-col'>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell className='exp-header--title completedcalls__header-border'>
                                        <div className='header--div' ref={dropdownRefs['expertName']}>
                                            <div className='header--divText'>
                                                <div></div>
                                                <span className='header--text'>
                                                    Expert Name
                                                </span>
                                            </div>
                                            <div className='filter--countwrap'>
                                                {
                                                    filters && filters['expertName']?.length > 0 &&
                                                    <div className='filter--count'><Image src={WhiteCrossIcon} alt="CrossIcon" className='cross--icon' onClick={() => handleClearFilter('expertName')} /></div>
                                                }
                                                <Dropdown
                                                    open={dropdownOpen['expertName']}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        if (!clickOutside) {
                                                            setDropdownOpen({
                                                                expertName: true,
                                                                expertTypes: false,
                                                                relevantCompanies: false,
                                                                networkProviders: false,
                                                                status: false,
                                                            });
                                                            setShowCrossIcon({
                                                                expertName: true,
                                                                expertTypes: false,
                                                                relevantCompanies: false,
                                                                networkProviders: false,
                                                                status: false,
                                                            })
                                                        }
                                                        else {
                                                            setClickOutside(false);
                                                        }
                                                    }}
                                                    closeOnChange={false}
                                                    icon={<Image src={showCrossIcon['expertName'] === true ? CrossIcon : SearchIcon} alt="icon" className={dropdownOpen['expertName'] ? 'filter--icon filtercross--icon' : 'filter--icon'}
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            handleCrossIconClick('expertName');
                                                        }
                                                        }
                                                    />}
                                                    floating
                                                    labeled
                                                    button
                                                    className="icon"
                                                >
                                                    <Dropdown.Menu className={'calls--menuLessData expertName--menu'}>
                                                        <Dropdown.Item key={0}>
                                                            <div className="dropdown--search">
                                                                <Dropdown.SearchInput type="string"
                                                                    value={expertNameSearchTerm}
                                                                    onChange={(e) => handleSearchChange(e, 'expertName')}
                                                                    onKeyUp={(e) => {
                                                                        handleKeyUp(e);
                                                                    }
                                                                    }
                                                                    placeholder="Search By Experts Name"
                                                                    className="search--input paddingnew" />
                                                                <Image src={GreenSearchIcon} alt="search icon" className={`search--icon greensearch--icon ${expertNameSearchTerm.length < 3 && "disable-icon"}`}
                                                                    onClick={() => searchExpertNameHandler(expertNameSearchTerm)} />
                                                            </div>
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell className='exp-header--title completedcalls__header-border'>
                                        <div className='header--div'>
                                            <div className='header--divText'>
                                                <span className='header--text'>
                                                    Relevant Position
                                                </span>
                                            </div>
                                        </div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell className='exp-header--title completedcalls__header-border'>
                                        <div className='header--div' ref={dropdownRefs['relevantCompanies']}>
                                            <div className='header--divText'>
                                                <span className='header--text'>
                                                    Relevant Company
                                                </span>
                                            </div>
                                            <div className='filter--countwrap'>
                                                {
                                                    filters?.relevantCompanies?.length > 0 &&
                                                    <span className='filter--count'>{filters?.relevantCompanies?.length} <Image src={WhiteCrossIcon} alt="CrossIcon" className='cross--icon' onClick={() => handleClearFilter('relevantCompanies')} /></span>
                                                }
                                                <Dropdown
                                                    open={dropdownOpen['relevantCompanies']}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        if (!clickOutside) {
                                                            setDropdownOpen({
                                                                expertName: false,
                                                                expertTypes: false,
                                                                relevantCompanies: true,
                                                                networkProviders: false,
                                                                status: false,
                                                            });
                                                            setShowCrossIcon({
                                                                expertName: false,
                                                                expertTypes: false,
                                                                relevantCompanies: true,
                                                                networkProviders: false,
                                                                status: false,
                                                            })
                                                        }
                                                        else {
                                                            setClickOutside(false);
                                                        }
                                                    }}
                                                    closeOnChange={false}
                                                    icon={<Image src={showCrossIcon['relevantCompanies'] === true ? CrossIcon : FilterIcon} alt="icon" className={dropdownOpen['relevantCompanies'] ? 'filter--icon filtercross--icon' : 'filter--icon'}
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            handleCrossIconClick('relevantCompanies');
                                                        }
                                                        }
                                                    />}
                                                    floating
                                                    labeled
                                                    button
                                                    className="icon"
                                                >
                                                    <Dropdown.Menu className={relevantCompaniesFilters?.length > 2 ? 'calls--menu calls--menumoreData' : 'calls--menu calls--menuLessData'}>

                                                        <div className="dropdown--search">
                                                            <Dropdown.SearchInput type="string"
                                                                value={relevantCompanySearchTerm}
                                                                onChange={(e) => handleSearchChange(e, 'relevantCompanies')}
                                                                onKeyUp={(e) => {
                                                                    handleKeyUp(e);
                                                                }
                                                                }
                                                                placeholder="Search Relevant Company"
                                                                className="search--input" />
                                                            <Image src={SearchIcon} alt="search icon" className="search--icon" />
                                                        </div>
                                                        {
                                                            relevantCompaniesFilters?.length > 0 ?
                                                                <>
                                                                    <>
                                                                        <Dropdown.Item key={-1}
                                                                            onClick={() => handleSelectAll('relevantCompaniesAll', relevantCompaniesFilters)}
                                                                            className='filter--item'>
                                                                            <div className='filter__chk'>
                                                                                <input
                                                                                    type="checkbox"
                                                                                    checked={filters['relevantCompanies']?.length === relevantCompaniesFilters?.length}
                                                                                    className='filter--checkBox'
                                                                                />
                                                                                <div className={filters['relevantCompanies']?.length === relevantCompaniesFilters?.length ? 'filter--itemtext selected' : 'filter--itemtext'}>Select All</div>
                                                                            </div>
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Divider className='drop--divider' />
                                                                    </>
                                                                    {relevantCompaniesFilters?.map((value, index) => (
                                                                        <>
                                                                            <Dropdown.Item key={index}
                                                                                onClick={() => handleChangeFilter('relevantCompanies', value?.name)}
                                                                                className='filter--item'>
                                                                                <div className='filter__chk'>
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        checked={filters['relevantCompanies']?.includes(value?.name)}
                                                                                        className='filter--checkBox' />
                                                                                    <div className={filters['relevantCompanies']?.includes(value) ? 'filter--itemtext selected' : 'filter--itemtext'}>{value?.name?.length > 17 ? value?.name?.substring(0, 17)?.concat('...') : value?.name}</div>
                                                                                </div>
                                                                                <div className='filter--countText'>{value?.count}</div>
                                                                            </Dropdown.Item>
                                                                            <>{index !== uniqueValuesForColumn('relevantCompanies')?.length - 1 && <Dropdown.Divider className='drop--divider' />}</>
                                                                        </>
                                                                    ))}
                                                                </>
                                                                :
                                                                <Dropdown.Item className='filter--item'>No Filters Available</Dropdown.Item>

                                                        }
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell className='exp-header--title completedcalls__header-border'>
                                        <div className='header--div' ref={dropdownRefs['expertTypes']}>
                                            <div className='header--divText'>
                                                <span className='header--text'>
                                                    Expert Type / Modules
                                                </span>
                                            </div>
                                            <div className='filter--countwrap'>
                                                {
                                                    filters?.expertTypes?.length > 0 &&
                                                    <span className='filter--count'>{filters?.expertTypes?.length} <Image src={WhiteCrossIcon} alt="CrossIcon" className='cross--icon' onClick={() => handleClearFilter('expertTypes')} /></span>
                                                }
                                                <Dropdown
                                                    open={dropdownOpen['expertTypes']}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        if (!clickOutside) {
                                                            setDropdownOpen({
                                                                expertName: false,
                                                                expertTypes: true,
                                                                relevantCompanies: false,
                                                                networkProviders: false,
                                                                status: false,
                                                            });
                                                            setShowCrossIcon({
                                                                expertName: false,
                                                                expertTypes: true,
                                                                relevantCompanies: false,
                                                                networkProviders: false,
                                                                status: false,
                                                            })
                                                        }
                                                        else {
                                                            setClickOutside(false);
                                                        }
                                                    }}
                                                    closeOnChange={false}
                                                    icon={<Image src={showCrossIcon['expertTypes'] === true ? CrossIcon : FilterIcon} alt="icon" className={dropdownOpen['expertTypes'] ? 'filter--icon filtercross--icon' : 'filter--icon'}
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            handleCrossIconClick('expertTypes');
                                                        }
                                                        }
                                                    />}
                                                    floating
                                                    labeled
                                                    button
                                                    className="icon"
                                                >
                                                    <Dropdown.Menu className={expertTypesFilters?.length > 2 ? 'calls--menu calls--menumoreData' : 'calls--menu calls--menuLessData'}>

                                                        {
                                                            expertTypesFilters?.length > 0 ?
                                                                <>
                                                                    <>
                                                                        <Dropdown.Item key={-1}
                                                                            onClick={() => handleSelectAll('expertTypesAll', expertTypesFilters)}
                                                                            className='filter--item'>
                                                                            <div className='filter__chk'>
                                                                                <input
                                                                                    type="checkbox"
                                                                                    checked={filters['expertTypes']?.length === expertTypesFilters?.length}
                                                                                    className='filter--checkBox'
                                                                                />
                                                                                <div className={filters['expertTypes']?.length === expertTypesFilters?.length ? 'filter--itemtext selected' : 'filter--itemtext'}>Select All</div>
                                                                            </div>
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Divider className='drop--divider' />
                                                                    </>
                                                                    {expertTypesFilters?.map((value, index) => (
                                                                        <>
                                                                            <Dropdown.Item key={index}
                                                                                onClick={() => handleChangeFilter('expertTypes', value?.name)}
                                                                                className='filter--item'>
                                                                                <div className='filter__chk'>
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        checked={filters['expertTypes']?.includes(value?.name)}
                                                                                        className='filter--checkBox' />
                                                                                    <div className={filters['expertTypes']?.includes(value) ? 'filter--itemtext selected' : 'filter--itemtext'}>{value?.name?.length > 17 ? value?.name?.substring(0, 17)?.concat('...') : value?.name}</div>
                                                                                </div>
                                                                                <div className='filter--countText'>{value?.count}</div>
                                                                            </Dropdown.Item>
                                                                            <>{index !== uniqueValuesForColumn('expertTypes')?.length - 1 && <Dropdown.Divider className='drop--divider' />}</>
                                                                        </>
                                                                    ))}
                                                                </>
                                                                :
                                                                <Dropdown.Item className='filter--item'>No Filters Available</Dropdown.Item>

                                                        }
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell className='exp-header--title completedcalls__header-border'>
                                        <div className='header--div' ref={dropdownRefs['networkProviders']}>
                                            <div className='header--divText'>
                                                <span className='header--text'>
                                                    Network
                                                </span>
                                            </div>
                                            <div className='filter--countwrap'>
                                                {
                                                    filters?.networkProviders?.length > 0 &&
                                                    <span className='filter--count'>{filters?.networkProviders?.length} <Image src={WhiteCrossIcon} alt="CrossIcon" className='cross--icon' onClick={() => handleClearFilter('networkProviders')} /></span>
                                                }
                                                <Dropdown
                                                    open={dropdownOpen['networkProviders']}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        if (!clickOutside) {
                                                            setDropdownOpen({
                                                                expertName: false,
                                                                expertTypes: false,
                                                                relevantCompanies: false,
                                                                networkProviders: true,
                                                                status: false,
                                                            });
                                                            setShowCrossIcon({
                                                                expertName: false,
                                                                expertTypes: false,
                                                                relevantCompanies: false,
                                                                networkProviders: true,
                                                                status: false,
                                                            })
                                                        }
                                                        else {
                                                            setClickOutside(false);
                                                        }
                                                    }}
                                                    closeOnChange={false}
                                                    icon={<Image src={showCrossIcon['networkProviders'] === true ? CrossIcon : FilterIcon} alt="icon" className={dropdownOpen['networkProviders'] ? 'filter--icon filtercross--icon' : 'filter--icon'}
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            handleCrossIconClick('networkProviders');
                                                        }
                                                        }
                                                    />}
                                                    floating
                                                    labeled
                                                    button
                                                    className="icon"
                                                >
                                                    <Dropdown.Menu className={networkProvidersFilters?.length > 2 ? 'calls--menu calls--menumoreData' : 'calls--menu calls--menuLessData'}>

                                                        {
                                                            networkProvidersFilters?.length > 0 ?
                                                                <>
                                                                    <>
                                                                        <Dropdown.Item key={-1}
                                                                            onClick={() => handleSelectAll('networkProvidersAll', networkProvidersFilters)}
                                                                            className='filter--item'>
                                                                            <div className='filter__chk'>
                                                                                <input
                                                                                    type="checkbox"
                                                                                    checked={filters['networkProviders']?.length === networkProvidersFilters?.length}
                                                                                    className='filter--checkBox'
                                                                                />
                                                                                <div className={filters['networkProviders']?.length === networkProvidersFilters?.length ? 'filter--itemtext selected' : 'filter--itemtext'}>Select All</div>
                                                                            </div>
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Divider className='drop--divider' />
                                                                    </>
                                                                    {networkProvidersFilters?.map((value, index) => (
                                                                        <>
                                                                            <Dropdown.Item key={index}
                                                                                onClick={() => handleChangeFilter('networkProviders', value?.name)}
                                                                                className='filter--item'>
                                                                                <div className='filter__chk'>
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        checked={filters['networkProviders']?.includes(value?.name)}
                                                                                        className='filter--checkBox' />
                                                                                    <div className={filters['networkProviders']?.includes(value) ? 'filter--itemtext selected' : 'filter--itemtext'}>{value?.name?.length > 17 ? value?.name?.substring(0, 17)?.concat('...') : value?.name}</div>
                                                                                </div>
                                                                                <div className='filter--countText'>{value?.count}</div>
                                                                            </Dropdown.Item>
                                                                            <>{index !== uniqueValuesForColumn('networkProviders')?.length - 1 && <Dropdown.Divider className='drop--divider' />}</>
                                                                        </>
                                                                    ))}
                                                                </>
                                                                :
                                                                <Dropdown.Item className='filter--item'>No Filters Available</Dropdown.Item>

                                                        }
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell className='exp-header--title completedcalls__header-border'>
                                        <div className='header--div' ref={dropdownRefs['startDate']}>
                                            <div className='header--divText'>
                                                <span className='header--text'>
                                                    Date
                                                </span>
                                            </div>
                                            <Image src={sortState === null ? SortIcon : (sortState === true ? SortAsc : SortDesc)} alt="icon" className='filter--icon'
                                                onClick={() => handleSort(!sortState)}
                                            />
                                        </div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell className='exp-header--title completedcalls__header-border'>
                                        <div className='header--div' ref={dropdownRefs['startTime']}>
                                            <div className='header--divText'>
                                                <span className='header--text'>
                                                    Start Time
                                                </span>
                                            </div>
                                        </div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell className='exp-header--title'>
                                        <div className='header--div' ref={dropdownRefs['status']}>
                                            <div className='header--divText'>
                                                <span className='header--text'>
                                                    Status
                                                </span>
                                            </div>
                                            <div className='filter--countwrap'>
                                                {
                                                    filters?.status?.length > 0 &&
                                                    <span className='filter--count'>{filters?.status?.length} <Image src={WhiteCrossIcon} alt="CrossIcon" className='cross--icon' onClick={() => handleClearFilter('status')} /></span>
                                                }
                                                <Dropdown
                                                    open={dropdownOpen['status']}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        if (!clickOutside) {
                                                            setDropdownOpen({
                                                                expertName: false,
                                                                expertTypes: false,
                                                                relevantCompanies: false,
                                                                networkProviders: false,
                                                                status: true,
                                                            });
                                                            setShowCrossIcon({
                                                                expertName: false,
                                                                expertTypes: false,
                                                                relevantCompanies: false,
                                                                networkProviders: false,
                                                                status: true,
                                                            })
                                                        }
                                                        else {
                                                            setClickOutside(false);
                                                        }
                                                    }}
                                                    closeOnChange={false}
                                                    icon={<Image src={showCrossIcon['status'] === true ? CrossIcon : FilterIcon} alt="icon" className={dropdownOpen['status'] ? 'filter--icon filtercross--icon' : 'filter--icon'}
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            handleCrossIconClick('status');
                                                        }
                                                        }
                                                    />}
                                                    floating
                                                    labeled
                                                    button
                                                    className="icon"
                                                >
                                                    <Dropdown.Menu className={statusFilters?.length > 2 ? 'calls--menu calls--menumoreData' : 'calls--menu calls--menuLessData'}>

                                                        {
                                                            statusFilters?.length > 0 ?
                                                                <>
                                                                    <>
                                                                        <Dropdown.Item key={-1}
                                                                            onClick={() => handleSelectAll('statusAll', statusFilters)}
                                                                            className='filter--item'>
                                                                            <div className='filter__chk'>
                                                                                <input
                                                                                    type="checkbox"
                                                                                    checked={filters['status']?.length === statusFilters?.length}
                                                                                    className='filter--checkBox'
                                                                                />
                                                                                <div className={filters['status']?.length === statusFilters?.length ? 'filter--itemtext selected' : 'filter--itemtext'}>Select All</div>
                                                                            </div>
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Divider className='drop--divider' />
                                                                    </>
                                                                    {statusFilters?.map((value, index) => (
                                                                        <>
                                                                            <Dropdown.Item key={index}
                                                                                onClick={() => handleChangeFilter('status', value?.name)}
                                                                                className='filter--item'>
                                                                                <div className='filter__chk'>
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        checked={filters['status']?.includes(value?.name)}
                                                                                        className='filter--checkBox' />
                                                                                    <div className={filters['status']?.includes(value) ? 'filter--itemtext selected' : 'filter--itemtext'}>{value?.name?.length > 17 ? value?.name?.substring(0, 17)?.concat('...') : value?.name}</div>
                                                                                </div>
                                                                                <div className='filter--countText'>{value?.count}</div>
                                                                            </Dropdown.Item>
                                                                            <>{index !== uniqueValuesForColumn('status')?.length - 1 && <Dropdown.Divider className='drop--divider' />}</>
                                                                        </>
                                                                    ))}
                                                                </>
                                                                :
                                                                <Dropdown.Item className='filter--item'>No Filters Available</Dropdown.Item>

                                                        }
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                        </Table>
                        <div id="scrollable-container">
                            <InfiniteScroll
                                dataLength={data?.length || 0}
                                hasMore={hasMore}
                                scrollableTarget="scrollable-container"
                                next={loadMoreData}
                                className='commTemplates__infiniteScroll'
                            >
                                <Table sortable celled className='calls--table'>
                                    <Table.Body className='grid__body'>
                                        {loading ? (
                                            <div className='loading--center'>
                                                <ImageParaSkeleton showPara={true} paraCount={5} size="fullWidth" height={4} />
                                            </div>
                                        ) : noRecFound ? (
                                            <div className='noRecFound'>
                                                Currently, there are no calls scheduled yet.
                                            </div>
                                        ) : (
                                            data?.map((item, index) => (
                                                <React.Fragment key={index}>
                                                    <Table.Row
                                                        className={item?.profileStatus?.toLowerCase() === 'new' && !item?.shortlisted ?
                                                            "exp--row rowShortlistedClr" : "exp--row rowDefaultClr"}
                                                        onClick={() => handleRowClick(item)}
                                                    >
                                                        <Table.Cell className='user--data table--cell expand--data'>
                                                            <Image src={expandedData === item?.id ? CollapseIcon : ExpandIcon} alt="Expand/Collapse" />
                                                        </Table.Cell>
                                                        <Table.Cell className='user--data table--cell expert--name'>
                                                            {item?.expertProfile?.expertName}
                                                        </Table.Cell>
                                                        <Table.Cell className='user--data table--cell expert--name'>
                                                            {item?.expertProfile?.relevantExperience?.title}
                                                        </Table.Cell>
                                                        <Table.Cell className='user--data table--cell expert--name'>
                                                            {item?.expertProfile?.relevantExperience?.employerName}
                                                        </Table.Cell>
                                                        <Table.Cell className='user--data table--cell other-col-width'>
                                                            {Array.isArray(item?.expertProfile?.expertTypes) ?
                                                                item?.expertProfile?.expertTypes.join(', ') :
                                                                item?.expertProfile?.expertTypes}
                                                        </Table.Cell>
                                                        <Table.Cell className='user--data table--cell other-col-width'>
                                                            {item?.expertProfile?.networkProvider}
                                                        </Table.Cell>
                                                        <Table.Cell className='user--data table--cell'>
                                                            {
                                                                handleDateDisplay(item)
                                                            }
                                                            {/* {item?.callStatus === 'Requested' ? 'NA' : item?.expertProfile?.startDate} */}
                                                        </Table.Cell>
                                                        <Table.Cell className='user--data table--cell'>
                                                            {
                                                                handleTimeDisplay(item)
                                                            }
                                                        </Table.Cell>
                                                        <Table.Cell className='status--cell'>
                                                            <div className={handleStatus(item?.callStatus)}>
                                                                {item?.callStatus}
                                                            </div>
                                                        </Table.Cell>
                                                    </Table.Row>

                                                    {expandedData === item?.id && (
                                                        <Table.Row className='template--div'>
                                                            <Table.Cell colSpan={9} className='template--cellwrap'>
                                                                <div className='template--cell'>
                                                                    <CallsTemplate data={expandedDataResp} handleExpandedState={handleExpandedState} />
                                                                </div>
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    )}
                                                </React.Fragment>
                                            ))
                                        )}
                                    </Table.Body>

                                </Table>
                            </InfiniteScroll>

                        </div>
                    </>
                    // :
                    // <>
                    //     {
                    //         noRecFound === true &&
                    //         <div className="templates__wrap">
                    //             <div className="templates--title">No Calls Scheduled Yet</div>
                    //             <div className="templates--subtitle">Your request is active, but no calls have been scheduled with experts yet. You can initiate scheduling by clicking &apos;Request Call&apos; on your selected expert&apos;s profiles. Check back here for managing upcoming calls.</div>

                    //             <Image src={ClosedRequestsIcon} alt="ClosedRequestsIcon" className="closedreq--icon" />
                    //         </div>
                    //     }
                    // </>
                }
            </div>
        </>
    );
};