
import { doAxiosRequest } from "config-axios";
import CONFIG from "config";
import * as utils from '@kdshared/okta-utils';
import expertTypeData from "staticjson/expertTypeData.json";

export const getUserProfilePictures = async (hrid) => {
    try {
        const axiosConfig = {
            method: 'POST',
            endpoint: CONFIG.API_URL.GET_PEOPLE,
            headers: {
                'x-api-key': CONFIG.PEOPLE_API_X_API_KEY,
                psid: utils.getPsId(),
                'content-type': CONFIG.HTTP_CONTENT_TYPES.json,
            },
            params: {
                query: getMultipleQueryData(hrid),
            },
        };
        const response = await doAxiosRequest(axiosConfig);
        if (response) {
            return response?.data?.employees;
        }
        else {
            return []
        }
    }
    catch (err) {
        console.log('Error in people api service', err);
    }
}

const getMultipleQueryData = (hrid) => {
    const idList = hrid.map(id => `"${id}"`).join(', ');
    return `query {
      employees(ids: [${idList}]) {
          id
        preferredName
        firstName
        lastName
        picture
        primaryWorkPhone
        email
        homeOffice
        businessTitle
        jobCategory
        jobPosition
        jobTitle
        hostOfficeLocation {
          id
          name
          city
          country
          countryCode
          timezone
      }
  phones{
          phoneNumber
          deviceType
          countryCode
          usage
      }
      }
  }
  `
}