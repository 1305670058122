import React, { useEffect, useRef, useState } from "react";
import "./ScheduleCall.scss";
import Modal from "components/shared/Modal";
import moment from 'moment-timezone';
import { DropdownField } from "components/shared/DropdownField";
import { Checkbox, Image } from "semantic-ui-react";
import { changeTimeFormat, msToTime } from "utils/helpers/helpers";
import CalIcon from "assets/images/blue_calender_icon.svg";
import ClockIcon from "assets/images/clock-icon.svg";
import InputClockIcon from "assets/images/clock-icon-green.svg";
import '@bcg-web/bcg-people-search';
import CONFIG from "config";
import authService from 'redux/auth';
import { getUserProfilePictures } from "api/getUserProfilePictures";
import CloseIcon from 'assets/images/close-chargeCode.svg';
import PlusIcon from 'assets/images/plus-large.svg';
import { DatePickerField } from "components/DatePickerField";
import CrossIcon from "assets/images/cross-large.svg";
import { setSaveCallSlotsAPIData } from "redux/actions/saveCallSlots/saveCallSlots.actions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

export const ScheduleCall = ({ open, data, caseTeamMembers, requestId, closePopupHandler }) => {
    const dispatch = useDispatch();
    const invitesFieldRef = useRef();

    const durationList = [
        { name: '15 mins', value: 15 },
        { name: '30 mins', value: 30 },
        { name: '60 mins', value: 60 },
        { name: '90 mins', value: 90 }
    ];

    const [openModel, setOpenModel] = useState(open);
    const [accessToken, setAccessToken] = useState([]);
    const [expertSectionOpen, setExpertSectionOpen] = useState(false);
    const [userSectionOpen, setUserSectionOpen] = useState(false);
    const [timeInputType, setTimeInputType] = useState('text');
    const [userSlotData, setUserSlotData] = useState([]);
    const [expertSlotData, setExpertSlotData] = useState([]);
    const [membersList, setMembersList] = useState([]);
    const [attendeeList, setAttendeeList] = useState([]);
    const [userSelection, setUserSelection] = useState({
        checked: true,
        date: '',
        duration: 60,
        durationDisplay: '60 mins',
        datetime: '',
        startTime: '',
        endTime: '',
        timezone: 'IST'
    });
    const timeZoneArray = [];

    useEffect(() => {
        setAccessToken(authService.getAccessToken());
        setUserSelection((selection) => {
            const updatedList = { ...selection };
            updatedList['timezone'] = moment().tz(moment.tz.guess()).format('z');
            return updatedList;
        });
    }, []);

    //useEffect for people search component
    useEffect(() => {
        let timeout;
        function updateAttendeeSelection(e) {
            if (e.detail && e.detail.length > 0) {
                let memberSelected = e.detail;
                setAttendeeList(() => {
                    const updatedMemberList = [];
                    memberSelected.forEach((select) => {
                        const newData = {
                            "attendeeEmail": select.email,
                            "attendeeHrId": select.id
                        };
                        updatedMemberList.push(newData);
                    });
                    return updatedMemberList;
                });
            }
        }
        if (invitesFieldRef && invitesFieldRef.current) {
            invitesFieldRef.current.removeFromSelectedList();
            const shadowRoot = invitesFieldRef.current.shadowRoot;
            timeout = setTimeout(() => {
                const element = shadowRoot.querySelector('.selection-list');
                if (element) {
                    element.style.overflow = 'auto';
                }
            }, 800);

            invitesFieldRef.current.addEventListener("selectedPeopleChanged", updateAttendeeSelection, false);
        }

        return () => clearTimeout(timeout);
    }, [invitesFieldRef.current]);

    useEffect(() => {
        if (caseTeamMembers?.length > 0) {
            handleRequestorName(caseTeamMembers);
        }
    }, [caseTeamMembers]);

    useEffect(() => {
        if (data.availableSlots?.length > 0) {
            data.availableSlots.forEach((avlSlot) => {
                const date = new Date(avlSlot.startDateTime);
                const selectedDateValue = date.toLocaleDateString();
                const slotDiff = msToTime(new Date(avlSlot.endDateTime).getTime() - new Date(avlSlot.startDateTime).getTime(), 'diff');//extractSlotDiff(avlSlot.startTime.slice(0, 5), avlSlot.endTime.slice(0, 5));

                const startTimeArray = changeTimeFormat(msToTime(new Date(avlSlot.startDateTime).getTime(), 'time'));
                const endTimeArray = changeTimeFormat(msToTime(new Date(avlSlot.endDateTime).getTime(), 'time'));

                const startTime = formatHrsMinsString(startTimeArray[0].toString()) + ':' + formatHrsMinsString(startTimeArray[2].toString());
                const endTime = formatHrsMinsString(endTimeArray[0].toString()) + ':' + formatHrsMinsString(endTimeArray[2].toString());

                let slotStartTime = avlSlot.startDateTime?.split('T')[1]?.slice(0, 5) ? avlSlot.startDateTime.split('T')[1].slice(0, 5) : '',
                    slotEndTime = avlSlot.endDateTime?.split('T')[1]?.slice(0, 5) ? avlSlot.endDateTime.split('T')[1].slice(0, 5) : '';

                setExpertSlotData((expertData) => {
                    let updatedData = [...expertData];
                    const duplicateSlotRecord = updatedData.findIndex((slot) => slot.date == selectedDateValue);
                    if (duplicateSlotRecord > -1) {
                        const oldTimeSlot = updatedData[duplicateSlotRecord].displayTimeString;
                        const newTimeSlot = slotDiff + ' (' + startTime + ' ' + startTimeArray[5] + ' - ' + endTime + ' ' + endTimeArray[5] + ')';
                        updatedData[duplicateSlotRecord].displayTimeString = oldTimeSlot + ' , ' + newTimeSlot;
                        updatedData[duplicateSlotRecord].startTime.push(slotStartTime);
                        updatedData[duplicateSlotRecord].endTime.push(slotEndTime);
                    } else {
                        const newSelectionDetails = {
                            date: selectedDateValue,
                            dateValue: selectedDateValue,
                            day: getDay(date),
                            userStartTime: '',
                            startTime: [slotStartTime],
                            endTime: [slotEndTime],
                            checked: false,
                            displayTimeString: 'Available Slots : ' + slotDiff + ' (' + startTime + ' ' + startTimeArray[5] + ' - ' + endTime + ' ' + endTimeArray[5] + ')',
                            timezone: userSelection.timezone
                        };
                        updatedData.push(newSelectionDetails);
                    }
                    return updatedData;
                });
            });
        }
    }, [data]);

    const handleRequestorName = async (caseTeam) => {
        let resp = [];
        let arr = [];
        for (let i = 0; i < caseTeam?.length; i++) {
            arr?.push(caseTeam[i]?.hrId);
            // if (caseTeam[i].memberType == "requestor") {
            //     requestor = caseTeam[i]?.hrId;
            // }
        }
        if (arr?.length > 0) {
            resp = await getUserProfilePictures(arr);
            setMembersList((member) => {
                let updatedList = [...member];
                if (resp?.length > 0) {
                    resp.forEach((record) => {
                        // if (record.id == requestor) {
                        //     console.log('requestor timezone',record.hostOfficeLocation.timezone);
                        // }
                        updatedList.push({ name: record?.lastName?.concat(', ').concat(record?.firstName), email: record.email, hrid: record.id });
                    });
                }
                return updatedList;
            });
        }
    }

    const formatHrsMinsString = (dataString) => {
        const requestString = dataString.length == 1 ? '0' + dataString : dataString;
        return requestString;
    }

    const getTimeInterval = (start, end) => {
        const starttimeArray = start.split(':'), endtimeArray = end.split(':');

        const hh = Number(starttimeArray[0].trim()) > 12 ? Number(starttimeArray[0].trim()) - 12 : Number(starttimeArray[0].trim());
        const mm = starttimeArray[1];

        const endMM = endtimeArray[1];
        const endHH = Number(endtimeArray[0].trim()) > 12 ? Number(endtimeArray[0].trim()) - 12 : Number(endtimeArray[0].trim());

        let endTimePeriod = (Number(endtimeArray[0].trim()) > 12 || Number(endtimeArray[0].trim()) == 12) ? 'PM' : 'AM',
            startTimePeriod = (Number(starttimeArray[0].trim()) > 12 || Number(starttimeArray[0].trim()) == 12) ? 'PM' : 'AM';

        const startHrs = formatHrsMinsString(hh.toString());
        const startMins = formatHrsMinsString(mm.toString());
        const endHrs = formatHrsMinsString(endHH.toString());
        const endMins = formatHrsMinsString(endMM.toString());

        if (endHrs == '00') {
            endTimePeriod = 'AM';
        }
        if (startHrs == '00') {
            startTimePeriod = 'AM';
        }

        const startTimeString = startHrs == '00' ? '12:' + startMins : startHrs + ':' + startMins;
        const endTimeString = endHrs == '00' ? '12:' + endMins : endHrs + ':' + endMins;

        const timeInterval = endTimePeriod == startTimePeriod ? startTimeString + ' - ' + endTimeString + ' ' + endTimePeriod : startTimeString + ' ' + startTimePeriod + ' - ' + endTimeString + ' ' + endTimePeriod;

        return { timeInterval };
    }

    const getDay = (date) => {
        const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        let selectedDay = weekday[date.getDay()];
        return selectedDay;
    }

    const addSlotHandler = () => {
        if (!userSelection.timeError) {
            if (userSelection.date && userSelection.startTime && userSelection.endTime && userSelection.timezone) {
                setUserSectionOpen(true);
                const selectedDate = new Date(userSelection.date);
                const selectedDateValue = selectedDate.toLocaleString().split(',')[0];
                setUserSlotData((userData) => {
                    let updatedData = [...userData];
                    const selectionData = getTimeInterval(userSelection.startTime, userSelection.endTime);
                    if (!userSelection.timeError) {
                        const duplicateSlotRecord = updatedData.findIndex((slot) => slot.date == selectedDateValue);
                        if (duplicateSlotRecord > -1) {
                            const newTimeSlot = selectionData.timeInterval;
                            if (updatedData[duplicateSlotRecord].time.findIndex((t) => t.slot == newTimeSlot) < 0) {
                                updatedData[duplicateSlotRecord].time.push({
                                    checked: true,
                                    slot: newTimeSlot,
                                    startTime: userSelection.startTime,
                                    endTime: userSelection.endTime
                                });
                                updatedData[duplicateSlotRecord].checked = true;
                            } else {
                                toast.info('Time already added.');
                            }
                        } else {
                            const newSelectionDetails = {
                                date: selectedDateValue,
                                day: getDay(selectedDate),
                                checked: true,
                                time: [{
                                    checked: true,
                                    slot: selectionData.timeInterval,
                                    startTime: userSelection.startTime,
                                    endTime: userSelection.endTime
                                }],
                                timezone: userSelection.timezone
                            };
                            updatedData.push(newSelectionDetails);
                        }
                    }
                    return updatedData;
                });
            } else {
                toast.error("Some values are missing.");
            }
        }
    }

    const handleSlotTimeSelection = (slotType, date, time) => {
        if (slotType == 'user') {
            let checkCount = 0;
            setUserSlotData((slotData) => {
                const slotUpdatedList = [...slotData];
                slotUpdatedList.forEach((slot) => {
                    let otherSelectedTimeIndex = -1;
                    if (slot.date == date) {
                        const timeIndex = slot.time.findIndex((t) => t.slot == time.slot);
                        if (timeIndex > -1) {
                            slot.checked = !slot.time[timeIndex].checked;
                            slot.time[timeIndex].checked = !slot.time[timeIndex].checked;
                        }
                        otherSelectedTimeIndex = slot.time.findIndex((t) => t.checked);
                    }
                    if (slot.checked || otherSelectedTimeIndex > -1) {
                        checkCount = checkCount + 1;
                    }
                });
                return slotUpdatedList;
            });

            setUserSectionOpen(checkCount > 0 ? true : false);
        } else {
            let checkedCount = 0;
            setExpertSlotData((slotData) => {
                const slotUpdatedList = [...slotData];
                slotUpdatedList.forEach((slot) => {
                    if (slot.date == date) {
                        slot.showTimeSelection = !slot.checked;
                        slot.checked = !slot.checked;
                    }
                    if (slot.checked) {
                        checkedCount = checkedCount + 1;
                    } else {
                        slot.userStartTime = '';
                    }
                    slot.userTimeFieldType = slot.userStartTime ? 'time' : 'text';
                });
                return slotUpdatedList;
            });
            setExpertSectionOpen(checkedCount > 0 ? true : false);
        }
    }

    const extractSelectedSlotsData = () => {
        const slotData = userSectionOpen ? userSlotData : expertSlotData;
        let selectedData = [];
        slotData.forEach((slot) => {
            if (userSectionOpen) {
                const anySlotSelected = slot.time.filter((t) => t.checked);
                if (anySlotSelected?.length > 0) {
                    anySlotSelected.forEach((us) => {
                        if (us.endTime && us.startTime) {
                            let availableDate = moment(slot.date, "MM-DD-YYYY").format('YYYY-MM-DD');
                            let formatedData = {
                                "endDateTime": availableDate + 'T' + us.endTime + 'Z',
                                "startDateTime": availableDate + 'T' + us.startTime + 'Z',
                                "isExpertSlot": !userSectionOpen
                            };
                            selectedData.push(formatedData);
                        }
                    })
                }
            } else {
                if (slot.checked) {
                    if (slot.userEndTime && slot.userStartTime && !slot.error) {
                        let slotDate = moment(slot.dateValue, "MM-DD-YYYY").format('YYYY-MM-DD');
                        let formatedData = {
                            "endDateTime": slotDate + 'T' + slot.userEndTime + 'Z',
                            "startDateTime": slotDate + 'T' + slot.userStartTime + 'Z',
                            "isExpertSlot": !userSectionOpen
                        };
                        selectedData.push(formatedData);
                    }
                }
            }
        });
        return selectedData;
    }

    const handleExpertSlotSelection = (event) => {
        let isValidTime = true;
        setExpertSlotData(() => {
            const slotUpdatedList = [...expertSlotData];
            const selectedRecordIndex = slotUpdatedList.findIndex((e) => e.date == event.target.id);
            slotUpdatedList[selectedRecordIndex].userStartTime = event.target.value;
            if (event.target.value?.includes(':')) {
                let timeArray = event.target.value.split(':');
                slotUpdatedList[selectedRecordIndex].userEndTime = getCallEndDate(timeArray[0], timeArray[1]);
                isValidTime = validateSelectedCallSlot(event.target.value, slotUpdatedList[selectedRecordIndex].userEndTime, slotUpdatedList[selectedRecordIndex].startTime, slotUpdatedList[selectedRecordIndex].endTime);
                slotUpdatedList[selectedRecordIndex].error = !isValidTime;
            } else {
                slotUpdatedList[selectedRecordIndex].userEndTime = '';
                slotUpdatedList[selectedRecordIndex].error = false;
            }
            return slotUpdatedList;
        });
    }

    const validateSelectedCallSlot = (startTime, endTime, startSlots, endSlots) => {
        let flag = false, validSlotCount = 0;
        const startTimeValue = Number(startTime?.replace(/:/g, ''));
        const endTimeValue = Number(endTime?.replace(/:/g, ''));
        startSlots.forEach((item, index) => {
            let availSlotTime = Number(item.replace(/:/g, ''));
            let availEndSlot = Number(endSlots[index]?.replace(/:/g, ''));
            if ((availSlotTime > availEndSlot && (startTimeValue < availEndSlot || startTimeValue > availSlotTime) && endTimeValue < availEndSlot)
                || (availSlotTime < startTimeValue && availEndSlot > endTimeValue)) {
                validSlotCount = validSlotCount + 1;
            }
        });
        if (validSlotCount > 0) {
            flag = true;
        }
        return flag;
    }

    const getCallEndDate = (hours, minutes) => {
        let durationMins = 0, durationHrs = Number(hours);
        if (userSelection.duration > 59) {
            durationMins = userSelection.duration - 60;
            durationHrs = durationHrs + 1;
        } else {
            durationMins = userSelection.duration;
        }
        if (minutes > 0) {
            durationMins = durationMins + Number(minutes);
            if (durationMins > 59) {
                durationMins = durationMins - 60;
                durationHrs = durationHrs + 1;
            }
        }
        if (String(durationMins).length == 1) {
            durationMins = '0' + String(durationMins);
        }

        if (String(durationHrs).length == 1) {
            durationHrs = '0' + String(durationHrs);
        }

        return durationHrs + ':' + durationMins;
    }

    const sendSlotsHandler = () => {
        const attendeeData = mergedMembers();
        const slotsSelectedData = extractSelectedSlotsData();
        if (attendeeData?.length > 0 && slotsSelectedData?.length > 0) {
            setOpenModel(false);
            closePopupHandler();
            const requestData = {
                "requestId": requestId,
                "projectId": data.projectId,
                "expertProfile": {
                    "id": data.id,
                    "profileId": data.profileId,
                    "networkProvider": data.networkProvider
                },
                "followUpCall": "false",
                "reason": null,
                "selectedSlots": slotsSelectedData,
                "attendees": attendeeData
            };
            dispatch(setSaveCallSlotsAPIData({ data: requestData }));
        } else {
            toast.error('Either attendee list or call slots were not selected.')
        }
    }

    const mergedMembers = () => {
        const updatedList = [...attendeeList];
        membersList.forEach((item) => {
            updatedList.push({
                "attendeeEmail": item.email,
                "attendeeHrId": item.hrid
            });
        })
        return updatedList;
    }

    const handleCaseTeamMemberRemoval = (hrid) => {
        setMembersList((member) => {
            let updatedList = [...member];
            return updatedList.filter((item) => item.hrid != hrid);
        })
    }

    return (
        <Modal isOpen={openModel} className="schedulecall__modal">
            <div className="schedulecall__wrapper model-wrapper">
                <div className="model-header">
                    <img className="close-icon" src={CrossIcon} alt="close" onClick={() => { setOpenModel(false); closePopupHandler(); }} />
                    <div className="schedulecall__heading">
                        <span>Schedule Call with {data?.expertName}</span>
                        {/* <p>Choose an available slot from the expert&lsquo;s schedule for your interview, or share your own availability to let the expert select a convenient time.</p> */}
                    </div>
                </div>
                <div className="model-body">
                    <div className="schedulecall__common-block">
                        <div className="time-timezone">
                            <div className="step-label">
                                <span className="step-count">Step 1 :</span> Select Call duration and time zone <span className="mandatory">*</span>
                            </div>
                            <div className='time-field expert-time-field right-margin'>
                                {/* <label htmlFor='duration' className="form-label">Select Duration</label> */}
                                <DropdownField data={durationList} fieldIndex='0' name='duration' fieldId='duration' placeholderTxt='Select duration'
                                    selectedValue={userSelection.durationDisplay}
                                    onSelectHandler={(selected) => {
                                        if (expertSectionOpen) {
                                            {
                                                let isValidTime = true;
                                                setExpertSlotData(() => {
                                                    const slotUpdatedList = [...expertSlotData];
                                                    slotUpdatedList.forEach((slot) => {
                                                        if (slot.userStartTime) {
                                                            let timeArray = slot.userStartTime.split(':');
                                                            slot.userEndTime = getCallEndDate(timeArray[0], timeArray[1]);
                                                            isValidTime = validateSelectedCallSlot(slot.userStartTime, slot.userEndTime, slot.startTime, slot.endTime);
                                                            slot.error = !isValidTime;
                                                        }
                                                    })
                                                    return slotUpdatedList;
                                                });
                                            }
                                        }
                                        setUserSelection((selection) => {
                                            const updatedList = { ...selection };
                                            updatedList['duration'] = selected.slice(0, 2);
                                            updatedList['durationDisplay'] = selected;

                                            if (userSectionOpen) {
                                                if (updatedList['startTime'] && updatedList['endTime']) {
                                                    const startTimeValue = updatedList['startTime'].replace(':', '');
                                                    const endTimeValue = updatedList['endTime'].replace(':', '');
                                                    const userSlotDiff = ((Number(endTimeValue.slice(0, 2)) - Number(startTimeValue.slice(0, 2))) * 60) + (Number(endTimeValue.slice(2, endTimeValue.length)) - Number(startTimeValue.slice(2, startTimeValue.length)));
                                                    if (endTimeValue < startTimeValue || userSlotDiff < selected.slice(0, 2)) {
                                                        updatedList['timeError'] = endTimeValue < startTimeValue ? 'End time should be greater then start time.' : 'Time slot should be greater then duration selected.';
                                                    } else {
                                                        updatedList['timeError'] = '';
                                                    }
                                                }
                                            }

                                            return updatedList;
                                        });
                                    }}
                                    onFocusOutHandler={() => { return true; }} />

                            </div>
                            <div className='time-field expert-time-field left-margin'>
                                {/* <label htmlFor='time' className="form-label">Select Time Zone</label> */}
                                <DropdownField data={timeZoneArray} fieldIndex='0' name='time' fieldId='time'
                                    selectedValue={userSelection.timezone}
                                    isDisabled={true}
                                    onSelectHandler={(selected) => {
                                        setUserSelection((selection) => {
                                            const updatedList = { ...selection };
                                            updatedList['timezone'] = selected;
                                            return updatedList;
                                        });
                                    }}
                                    onFocusOutHandler={() => { return true; }} />

                            </div>
                        </div>
                        <div className="meeting-invites">
                            <div className="step-label">
                                <span className="step-count">Step 2 :</span> Choose case team member(s) to be added  to the Invite
                            </div>
                            {invitesFieldRef &&
                                <div className='invite-input'>
                                    <bcg-people-search app-name={CONFIG.applicationName} name='inviteInput' limit="20" width="100%" is-show-profile="false" is-multi-select="true"
                                        ref={invitesFieldRef} api-key={CONFIG.PEOPLE_API_X_API_KEY} access-token={accessToken} environment='preproduction-internal' />
                                </div>
                            }
                            {membersList?.length > 0 && <div className="edit-caseteam">
                                {membersList.map((mem, index) =>
                                    <div className={`saved-case-team-member ${mem.duplicateValue && 'duplicate-selection'}`} key={index}><span>{mem.name}</span>&ensp;<Image src={CloseIcon} onClick={() => handleCaseTeamMemberRemoval(mem.hrid)} /></div>
                                )}
                            </div>
                            }
                        </div>
                    </div>
                    <div className="schedulecall__details">
                        <div className="expert-slots">
                            <div className="step-label slot-labels">
                                <span className="step-count">Step 3 :</span> Choose a slot from expert&lsquo;s availability
                            </div>
                            <div className={`slots-available ${userSectionOpen && 'disable-section'}`}>
                                {expertSlotData?.length > 0 &&
                                    expertSlotData.map((card, index) => (
                                        <div key={index} className={`slot-card ${index != expertSlotData.length - 1 && 'div-bottom-border'}`}>
                                            <div className="level1 slot-details">
                                                <div className="slot-date">
                                                    <Checkbox className="een-checkbox" checked={card.checked} disabled={userSectionOpen} onChange={() => handleSlotTimeSelection('expert', card.date)}></Checkbox>
                                                    &nbsp;&nbsp;{card.date} &nbsp;
                                                    <div className="slot-day other-details">{'(' + card.day + ')'}</div>
                                                </div>
                                                <div className="slot-data other-details"><div className="blank-div"></div>{card.displayTimeString}</div>

                                                {card.showTimeSelection &&
                                                    <div className='start-time-expert-field'>
                                                        <label htmlFor='usertime' className="form-label">Start Time</label>
                                                        <div>
                                                            <input type={card.userTimeFieldType}
                                                                id={card.date}
                                                                name={'expertTime' + index}
                                                                placeholder='HH : MM AM/PM'
                                                                value={card.userStartTime}
                                                                className="input-fields form-control"
                                                                onFocus={() => {
                                                                    setExpertSlotData((slotData) => {
                                                                        const slotUpdatedList = [...slotData];
                                                                        const selectedRecordIndex = slotUpdatedList.findIndex((e) => e.date == card.date);
                                                                        slotUpdatedList[selectedRecordIndex].userTimeFieldType = 'time';
                                                                        return slotUpdatedList;
                                                                    })
                                                                }
                                                                }
                                                                onBlur={() => {
                                                                    setExpertSlotData((slotData) => {
                                                                        const slotUpdatedList = [...slotData];
                                                                        const selectedRecordIndex = slotUpdatedList.findIndex((e) => e.date == card.date);
                                                                        slotUpdatedList[selectedRecordIndex].userTimeFieldType = slotUpdatedList[selectedRecordIndex].userStartTime ? 'time' : 'text';
                                                                        return slotUpdatedList;
                                                                    })
                                                                }}
                                                                onChange={(event) => handleExpertSlotSelection(event)}
                                                            />
                                                            <Image className='expert-time-img' src={InputClockIcon} alt="clock icon" />
                                                        </div>
                                                        <div className="help-txt"><span>Please enter a desired start time for the provided time slots.</span></div>
                                                        {card.error && <div className="error-txt">Please select valid start time/end time.</div>}
                                                    </div>
                                                }
                                                {/* <div className="other-details"><div className="blank-div"></div>{card.timezone}</div> */}
                                            </div>
                                        </div>
                                    ))}
                            </div>

                        </div>
                        <div className="user-slots-section">
                            <div className="step-label slot-labels">
                                <span className="step-count">Step 4 :</span> If expert&lsquo;s availability is not provided or suitable for you, share your availability with networks
                            </div>
                            <div className="slots-available">
                                <div className="user-slots">
                                    <div className='time-field user-date-field right-margin'>
                                        <label htmlFor='userDate' className="form-label">Date</label>
                                        <DatePickerField
                                            fieldId='userDate'
                                            minDateValue={new Date()}
                                            isDisabled={expertSectionOpen}
                                            savedValue={userSelection.date}
                                            onDateSelectHandler={(value) =>
                                                setUserSelection((selection) => {
                                                    const updatedList = { ...selection };
                                                    updatedList['date'] = value;
                                                    return updatedList;
                                                })}
                                            onCalClose={(event) => { console.log('scheduler calender closed', event); }} />
                                    </div>
                                    <div className='time-field user-time-field'>
                                        <label htmlFor='userstarttime' className="form-label">From</label>
                                        <div className="time-input-field">
                                            <input type='text' id='userstarttime' name='userstarttime' disabled={expertSectionOpen} placeholder='HH : MM AM/PM' value={userSelection.startTime} className="input-fields form-control"
                                                onFocus={(e) => e.target.type = 'time'}
                                                onBlur={(e) => {
                                                    if (!userSelection.startTime) {
                                                        e.target.type = 'text';
                                                    }
                                                }}
                                                onChange={(event) => {
                                                    setUserSelection((selection) => {
                                                        const updatedList = { ...selection };
                                                        updatedList['startTime'] = event.target.value;
                                                        if (event.target.value?.includes(':') && updatedList['endTime']) {
                                                            const startTimeValue = event.target.value.replace(':', '');
                                                            const endTimeValue = updatedList['endTime'].replace(':', '');
                                                            const userSlotDiff = ((Number(endTimeValue.slice(0, 2)) - Number(startTimeValue.slice(0, 2))) * 60) + (Number(endTimeValue.slice(2, endTimeValue.length)) - Number(startTimeValue.slice(2, startTimeValue.length)));
                                                            if (endTimeValue < startTimeValue || userSlotDiff < updatedList.duration) {
                                                                updatedList['timeError'] = endTimeValue < startTimeValue ? 'End time should be greater then start time.' : 'Time slot should be greater then duration selected.';
                                                            } else {
                                                                updatedList['timeError'] = '';
                                                            }
                                                        }
                                                        return updatedList;
                                                    });
                                                }}
                                            />
                                            <Image className='user-time-img' src={InputClockIcon} alt="clock icon" />
                                        </div>
                                    </div>
                                    <div className='time-field user-time-field'>
                                        <label htmlFor='userendtime' className="form-label">Till</label>
                                        <div className="time-input-field">
                                            <input type='text' id='userendtime' name='userendtime' disabled={expertSectionOpen} placeholder='HH : MM AM/PM' value={userSelection.endTime} className="input-fields form-control"
                                                min={userSelection.startTime}
                                                onFocus={(e) => e.target.type = 'time'}
                                                onBlur={(e) => {
                                                    if (!userSelection.endTime) {
                                                        e.target.type = 'text';
                                                    }
                                                }}
                                                onChange={(event) => {
                                                    setUserSelection((selection) => {
                                                        const updatedList = { ...selection };
                                                        updatedList['endTime'] = event.target.value;
                                                        if (event.target.value?.includes(':') && updatedList['startTime']) {
                                                            const startTimeValue = updatedList['startTime'].replace(':', '');
                                                            const endTimeValue = event.target.value.replace(':', '');
                                                            const userSlotDiff = ((Number(endTimeValue.slice(0, 2)) - Number(startTimeValue.slice(0, 2))) * 60) + (Number(endTimeValue.slice(2, endTimeValue.length)) - Number(startTimeValue.slice(2, startTimeValue.length)));
                                                            if (endTimeValue < startTimeValue || userSlotDiff < updatedList.duration) {
                                                                updatedList['timeError'] = endTimeValue < startTimeValue ? 'End time should be greater then start time.' : 'Time slot should be greater then duration selected.';
                                                            } else {
                                                                updatedList['timeError'] = '';
                                                            }
                                                        }
                                                        return updatedList;
                                                    });
                                                }}
                                            />
                                            <Image className='user-time-img' src={InputClockIcon} alt="clock icon" />
                                        </div>
                                    </div>
                                </div>
                                {userSelection.timeError && <div className="error-txt time-error"><div className="blank-block"></div><span>{userSelection.timeError}</span></div>}
                                <div className='slot-button-section'>
                                    <button className="add-slot" disabled={expertSectionOpen} onClick={(e) => { e.preventDefault(); addSlotHandler(); }}>
                                        <Image src={PlusIcon} alt='plus icon' />
                                        Add Slot
                                    </button>
                                </div>
                                {userSlotData.length > 0 &&
                                    <div className={`${expertSectionOpen && 'disable-section'}`}>
                                        <span className="selected-slots-header">Selected Slots</span>
                                        {userSlotData.map((slot, index) => (
                                            <div key={index} className={`slot-card ${index != userSlotData.length - 1 && 'slot-border'}`}>
                                                <div className="level1 slot-details">
                                                    <div className="slot-date"> <Image src={CalIcon} alt="calender icon" />{slot.date}</div>
                                                    <div className="slot-day other-details"><div className="blank-div"></div>{slot.day}</div>
                                                    <div className="slot-time other-details">
                                                        {slot.time.map((t, i) => (
                                                            <div key={i} className="one-slot-block">
                                                                <Checkbox className="een-checkbox" checked={t.checked} disabled={expertSectionOpen} onChange={() => handleSlotTimeSelection('user', slot.date, t)}></Checkbox>
                                                                <Image src={ClockIcon} alt="clock icon" />{t.slot}
                                                            </div>
                                                        ))}
                                                    </div>
                                                    {/* <div className="other-details"><div className="blank-div"></div>{card.timezone}</div> */}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="schedulecall__action-container model-footer">
                    <button className="default-button" onClick={() => { sendSlotsHandler() }}>Send Slots to Network</button>
                </div>
            </div>
        </Modal>
    );
};