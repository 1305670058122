export const EXPERT_INTERVIEW_FIELDS = [
    {
        id: "projectOverview",
        label: "Request Overview",
        type: "title",
        placeholder: "",
        helpTxt: "",
        isValid: true
    },
    {
        id: "chargeCode",
        label: "1. Charge Code",
        type: "searchable",
        placeholder: "",
        helpTxt: "If Charge Code is not listed, please leave blank.",
        isValid: true,
        value: ''
    },
    {
        id: "requestTitle",
        label: "2. Request Title",
        type: "text",
        placeholder: "Add Request Title",
        helpTxt: "",
        error: "Enter request title.",
        isValid: true,
        value: '',
        isMandatory: true,
    },
    {
        id: "projectType",
        label: "3. Project Type",
        type: "select",
        isMultiSelect: false,
        customFieldOption: false,
        data: ['Corporate Strategy 1', 'Corporate Strategy 2', 'Corporate Strategy 3', 'Corporate Strategy 4'],
        placeholder: "Select Type",
        helpTxt: "Please ensure the selected project type is correct as changing it after submission is not possible.",
        error: "Select project type.",
        multiSelect: false,
        isValid: true,
        value: '',
        isMandatory: true,
    },
    {
        id: "caseTeam",
        label: "4. Add Case Team Members",
        type: "custom",
        placeholder: "Search by First Name or/and Last Name",
        helpTxt: "",
        isValid: true,
        value: ''
    },
    {
        id: "requestSpecifications",
        label: "Request Specifications",
        type: "title",
        placeholder: "",
        helpTxt: "",
        isValid: true
    },
    {
        id: "requestDescription",
        label: "1. Describe your request and the expert profiles you're looking for",
        type: "textarea",
        placeholder: "Add Description",
        helpTxt: "Please include: request overview and objective, industry, geography, description of experts needed, target and off-limit companies, screening questions.",
        error: "Describe your request and the expert profiles you're looking for.",
        isValid: true,
        value: '',
        isMandatory: true,
    },
    {
        id: "clientIndustry",
        label: "2. Industry your end-client operates in",
        type: "text",
        placeholder: "Please enter end-client industry",
        helpTxt: "",
        error: "Enter industry your end-client operates in.",
        isValid: true,
        value: '',
        isMandatory: true,
    },
    {
        id: "offLimitCompanies",
        label: "3. Off-limits Companies",
        type: "text",
        placeholder: "Please enter companies to be exclude",
        helpTxt: "If the request is sensitive, leave blank or provide several companies to mask client/target.",
        isValid: true,
        value: ''
    },
    {
        id: "expertType",
        label: "4. Expert Types/Modules",
        type: "select",
        isMultiSelect: true,
        customFieldOption: true,
        data: ['Customers', 'Competitors', 'Target', 'Suppliers', 'Distributors'],
        placeholder: "Select Expert Types/Modules",
        helpTxt: "",
        multiSelect: true,
        isValid: true,
        value: []
    },
    {
        id: "expertContact",
        label: "5. Assign Team Member to Module",
        type: "custom",
        data: [],
        placeholder: "Select an added Expert Type ",
        helpTxt: "Use this field to designate a team member as the main point of contact for an Expert Type/Module.",
        multiSelect: true,
        isValid: true,
        value: ''
    },
    {
        id: "interviewAnticipatedCount",
        label: "6. Anticipated number of Interviews",
        type: "number",
        placeholder: "Please enter number of interviews",
        helpTxt: "",
        error: "Enter valid anticipated number of interviews.",
        isValid: true,
        value: '',
        isMandatory: true
    },
    {
        id: "providers",
        label: "7. Select Expert Networks to send request to",
        type: "custom",
        placeholder: "Select Expert Networks to send request to",
        helpTxt: "For the duration of the EEH trial, only global expert networks can share expert profiles here (in External Expert Hub). With local expert networks, the workflow remains the same, i.e. profiles are delivered via email or providers' portals.",
        error: "Select at least one expert network.",
        isValid: true,
        value: '',
        isMandatory: true,
    },
    {
        id: "resourceManager",
        label: "8. Research Manager(s) at Expert Network(s)",
        type: "custom",
        placeholder: "Enter email address of preferred RM",
        helpTxt: "If you have a preferred research manager or other point of contact at an Expert Network, you can provide their email address in this field.",
        isValid: true,
        value: ''
    },
    {
        id: "interviewStartDate",
        label: "9. Interview Start Date",
        type: "date",
        placeholder: "DD / MM / YYYY",
        size: "small",
        helpTxt: "",
        error: "Select interview start date.",
        isValid: true,
        value: '',
        isMandatory: true,
    },
    {
        id: "interviewEndDate",
        label: "10. Interview End Date",
        type: "date",
        placeholder: "DD / MM / YYYY",
        size: "small",
        helpTxt: "",
        error: "Select interview end date.",
        isValid: true,
        value: '',
        isMandatory: true,
    },
    {
        id: "requestorDetails",
        label: "Requestor Information",
        type: "title",
        placeholder: "",
        helpTxt: "",
        isValid: true,
        value: ''
    },
    {
        id: "requestor",
        label: "1. Requestor's Email ID",
        type: "readonly",
        placeholder: "",
        helpTxt: "",
        isValid: true,
        error: "Please enter valid email address.",
        value: '',
        isMandatory: true,
    },
    {
        id: "phoneNo",
        label: "2. Phone Number",
        type: "custom",
        placeholder: "",
        helpTxt: "",
        isValid: true,
        value: ''
    },
    {
        id: "office",
        label: "3. Home Office",
        type: "readonly",
        placeholder: "Default Home Office",
        helpTxt: "",
        size: "small",
        isValid: true,
        value: '',
        isMandatory: true,
    },
    {
        id: "timezone",
        label: "4. Time Zone",
        type: "readonly",
        placeholder: "Default Time Zone",
        helpTxt: "",
        size: "small",
        isValid: true,
        value: '',
        isMandatory: true,
    },
    {
        id: "supportingDocumnets",
        label: "Supporting Documents",
        type: "title",
        placeholder: "",
        helpTxt: "",
        isValid: true,
        value: ''
    },
    {
        id: "upload",
        label: "",
        type: "custom",
        placeholder: "",
        helpTxt:"150MB limit. Alternatively,enter Sharepoint link in description.",
        isValid: true
    }
];

export const EXPERT_SURVEY_FIELDS = [
    {
        id: "projectOverview",
        label: "Request Overview",
        type: "title",
        placeholder: "",
        helpTxt: "",
        isValid: true
    },
    {
        id: "chargeCode",
        label: "1. Charge Code",
        type: "searchable",
        placeholder: "",
        helpTxt: "If Charge Code is not listed, please leave blank.",
        isValid: true,
        value: ''
    },
    {
        id: "requestTitle",
        label: "2. Request Title",
        type: "text",
        placeholder: "Add Request Title",
        helpTxt: "",
        error: "Enter request title.",
        isValid: true,
        value: '',
        isMandatory: true,
    },
    {
        id: "projectType",
        label: "3. Project Type",
        type: "select",
        isMultiSelect: false,
        customFieldOption: false,
        data: [],
        placeholder: "Select Type",
        helpTxt: "",
        error: "Select project type.",
        multiSelect: false,
        isValid: true,
        value: '',
        isMandatory: true,
    },
    {
        id: "caseTeam",
        label: "4. Add Case Team Members",
        type: "custom",
        placeholder: "Search by First Name or/and Last Name",
        helpTxt: "",
        isValid: true,
        value: ''
    },
    {
        id: "requestSpecifications",
        label: "Request Specifications",
        type: "title",
        placeholder: "",
        helpTxt: "",
        isValid: true
    },
    {
        id: "requestDescription",
        label: "1. Describe your request and the expert profiles you're looking for",
        type: "textarea",
        placeholder: "Add Description",
        helpTxt: "Please include: request overview and objective, industry, geography, description of experts needed, target and off-limit companies, screening questions.",
        error: "Describe your request and the expert profiles you're looking for.",
        isValid: true,
        value: '',
        isMandatory: true,
    },
    {
        id: "incQuery",
        label: "2. Will you be coding in Inc-Query?",
        subLabel: "If yes 1) Program your survey directly in Inc-Query 2) Connect Inc-Query with the selected external network(s).",
        type: "toggle",
        placeholder: "",
        helpTxt: "",
        error: "",
        isValid: true,
        value: false,
        isMandatory: false,
    },
    {
        id: "totalSurvey",
        label: "3. Total number of survey respondents required",
        type: "number",
        placeholder: "Please enter number of survey respondents",
        helpTxt: "",
        isValid: true,
        value: '',
        error: "Enter valid number of survey respondents.",
        isMandatory: true,
    },
    {
        id: "providers",
        label: "4. Select Expert Networks to send request to",
        type: "custom",
        placeholder: "Select Expert Networks to send request to",
        helpTxt: "For the duration of the EEH trial, only global expert networks can share expert profiles here (in External Expert Hub). With local expert networks, the workflow remains the same, i.e. profiles are delivered via email or providers' portals.",
        error: "Select at least one expert network.",
        isValid: true,
        value: '',
        isMandatory: true,
    },
    {
        id: "resourceManager",
        label: "5. Research Manager(s) at Expert Network(s)",
        type: "custom",
        placeholder: "Enter email address of preferred RM",
        helpTxt: "If you have a preferred research manager or other point of contact at an Expert Network, you can provide their email address in this field.",
        isValid: true,
        value: ''
    },
    {
        id: "surveyStartDate",
        label: "6. Survey Start Date",
        type: "date",
        placeholder: "DD / MM / YYYY",
        size: "small",
        helpTxt: "",
        error: "Select survey start date.",
        isValid: true,
        value: '',
        isMandatory: true,
    },
    {
        id: "surveyEndDate",
        label: "7. Survey End Date",
        type: "date",
        placeholder: "DD / MM / YYYY",
        size: "small",
        helpTxt: "",
        error: "Select survey end date.",
        isValid: true,
        value: '',
        isMandatory: true,
    },
    {
        id: "requestorDetails",
        label: "Requestor Details",
        type: "title",
        placeholder: "",
        helpTxt: "",
        isValid: true,
        value: ''
    },
    {
        id: "requestor",
        label: "1. Requestor's Email ID",
        type: "readonly",
        placeholder: "",
        helpTxt: "",
        isValid: true,
        value: '',
        isMandatory: true,
    },
    {
        id: "phoneNo",
        label: "2. Phone Number",
        type: "custom",
        placeholder: "",
        helpTxt: "",
        isValid: true,
        value: ''
    },
    {
        id: "office",
        label: "3. Home Office",
        type: "readonly",
        placeholder: "Default Home Office",
        helpTxt: "",
        size: "small",
        isValid: true,
        value: '',
        isMandatory: true,
    },
    {
        id: "timezone",
        label: "4. Time Zone",
        type: "readonly",
        placeholder: "Default Time Zone",
        helpTxt: "",
        size: "small",
        isValid: true,
        value: '',
        isMandatory: true,
    },
    {
        id: "supportingDocumnets",
        label: "Supporting Documents",
        type: "title",
        placeholder: "",
        helpTxt: "",
        isValid: true,
        value: ''
    },
    {
        id: "upload",
        label: "",
        type: "custom",
        placeholder: "",
        helpTxt:"150MB limit. Alternatively,enter Sharepoint link in description.",
        isValid: true
    }
];