import React, { useEffect, useState } from "react";
import "./ExpertProfile.scss";
import moment from 'moment';
import Modal from "components/shared/Modal";
import { Image } from "semantic-ui-react";
import CrossIcon from "assets/images/cross-large.svg";
import RecommendIcon from "assets/images/recommended-icon.svg";
import MinusIcon from "assets/images/minus-outline.svg";
import StarIcon from "assets/images/star-outline.svg";
import FilledStarIcon from "assets/images/star.svg";
import PinIcon from "assets/images/location-pin.svg";
import UserIcon from "assets/images/user-icon.svg";
import CalIcon from "assets/images/blue_calender_icon.svg";
import SlackIcon from "assets/images/slack-icon.svg";
import ClockIcon from "assets/images/clock-outline.svg";
import MoreInfoIcon from "assets/images/info-orange.svg";
import NextArrow from "assets/images/arrow-right.svg";
import PrevArrow from "assets/images/arrow-left.svg";
import { Tooltip } from 'react-tooltip';
import { DataDisplayGrid } from "components/shared/DataDisplayGrid/DataDisplayGrid";
import { setExpertProfileActionsAPIData } from "redux/actions/expertProfileActions/expertProfileActions.actions";
import { selectors as profileActionSelector } from "redux/reducers/expertProfileActions/expertProfileActions_reducer";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';

export const ExpertProfile = ({ open, data, selectedIndex, lastRecord, selectedRecord, closePopupHandler, carouselActionClicked, openScheduler, handleDuplicateProfilesClick, duplicateProfiles }) => {
    // const networkPopupRef = useRef(null);    Kirti: may required in future.
    const dispatch = useDispatch();

    const profileActionStatus = useSelector(profileActionSelector.getSaveProfileActionsResults);

    const empColumns = [
        { name: 'Employer', value: 'employer' },
        { name: 'Title', value: 'title' },
        { name: 'Tenure', value: 'tenure' },
        { name: 'Home Office', value: 'homeOffice' }
    ];
    const expColumns = [
        { name: 'Employer', value: 'employer' },
        { name: 'Title', value: 'title' },
        { name: 'Start Date', value: 'startDate' },
        { name: 'End Date', value: 'endDate' },
        { name: 'Tenure', value: 'tenure' },
        { name: 'Home Office', value: 'homeOffice' }
    ];

    const [expData, setExpData] = useState([]);
    const [empData, setEmpData] = useState([]);
    const [openModel, setOpenModel] = useState(open);
    // kirti: may required in future
    // const [isNetworkPopupOpen, setIsNetworkPopupOpen] = useState(false);
    // const [selectedNetwork, setSelectedNetwork] = useState('');
    // const [externalNetworkData, setExternalNetworkData] = useState();
    const [profileSelected, setSelectedProfile] = useState(0);
    const [actionMadeFlag, setActionMadeFlag] = useState(true);
    const [duplicateCount, setDuplicateCount] = useState([]);

    useEffect(() => {
        if (data) {
            if (data.relevantExperience) {
                setExpData((prevData) => {
                    let dataItems = [...prevData];
                    const tenureAvailable = ((data.relevantExperience.tenureYear != null && data.relevantExperience.tenureYear > -1) && data.relevantExperience.tenureMonth != null && data.relevantExperience.tenureMonth > -1)
                    let relevantExperience = {
                        employer: data.relevantExperience.employerName,
                        title: data.relevantExperience.title,
                        startDate: moment().set('month', data.relevantExperience?.startMonth - 1 || '')?.format('MMM') + ' ' + data?.relevantExperience?.startYear || '',
                        endDate: data.relevantExperience.employerStatus == "Current" ? 'Present' : moment().set('month', data.relevantExperience?.endMonth - 1 || '')?.format('MMM') + ' ' + data?.relevantExperience?.endYear || '',
                        tenure: tenureAvailable ? data.relevantExperience.tenureYear + ' yrs ' + data.relevantExperience.tenureMonth + ' mo' : '',
                        homeOffice: data.relevantExperience.homeOffice
                    };
                    dataItems = [relevantExperience];
                    return dataItems;
                });
            }
            if (data.employmentHistory?.length > 0) {
                setEmpData((prevData) => {
                    let dataItems = [];
                    data.employmentHistory.forEach((emp, index) => {
                        let empStart = moment().set('month', emp.startMonth - 1 || '')?.format('MMM') + ' ' + emp.startYear || '',
                            empEnd = emp.currentEmployment ? 'Present' : moment().set('month', emp.endMonth - 1 || '')?.format('MMM') + ' ' + emp.endYear || '',
                            yrGap = emp.endYear - emp.startYear,
                            monthGap = emp.endMonth > emp.startMonth ? emp.endMonth - emp.startMonth : ((12 - emp.startMonth) + emp.endMonth);
                           if (monthGap > 11){
                            yrGap = yrGap+1;
                            monthGap = monthGap-12;
                           }
                            let empTotal = '';
                            if(!emp.currentEmployment){
                                empTotal = monthGap > 0 ? '(' + yrGap + '.' + monthGap + ' yrs)' : '(' + yrGap + ' yrs)';
                            }
                        dataItems.push({
                            employer: emp.employerName,
                            title: emp.title,
                            tenure: empStart + ' - ' + empEnd + ' ' + empTotal,
                            homeOffice: emp.homeOffice
                        });
                    });
                    return dataItems;
                });
            }
        }
    }, [data]);

    useEffect(() => {
        if (duplicateProfiles?.length > 0) {
            setDuplicateCount(duplicateProfiles);
        }
    }, [duplicateProfiles]);

    useEffect(() => {
        setSelectedProfile(selectedIndex);
    }, [selectedIndex]);

    useEffect(() => {
        if (profileActionStatus?.message) {
            !actionMadeFlag ? toast.success(profileActionStatus?.message) : '';
            setActionMadeFlag(true);
        }
    }, [profileActionStatus]);

    // kirti: may required in future
    // useEffect(() => {
    //     if (externalNetworkData?.length > 0) {
    //         setSelectedNetwork(externalNetworkData[0]);
    //     }
    // }, [externalNetworkData]);

    // kirti: may required in future
    // useEffect(() => {
    //     const handleClickOutside = (event) => {
    //         if (networkPopupRef.current && !networkPopupRef.current.contains(event.target)) {
    //             setIsNetworkPopupOpen(false);
    //         }
    //     };

    //     const handleMouseDown = (event) => {
    //         // Check if the down arrow image was clicked
    //         // if (!event.target.classList.contains('arrow-img') && !event.target.classList.contains('dropdown--textbox')) {
    //         handleClickOutside(event);
    //     };
    //     document.addEventListener("mousedown", handleMouseDown);

    //     return () => {
    //         document.removeEventListener("mousedown", handleMouseDown);
    //     };
    // }, []);

    const profileActionHandler = (type) => {
        setActionMadeFlag(false);
        dispatch(setExpertProfileActionsAPIData({ "expertProfileIds": [data.id], "action": type }));
    };
    return (
        <>
            <Modal isOpen={openModel} className="expertprofile__modal">
                <div>
                    <button className='carousel-btn' onClick={(event) => carouselActionClicked('previous', profileSelected)} disabled={profileSelected == 0}>
                        <Image src={PrevArrow} className='edit--icon' alt="previous icon" />
                        <span className='disable-btn'>Previous</span>
                    </button>
                </div>
                <div className="expertprofile__main-div">
                    <div className="expertprofile__wrapper model-wrapper">
                        <div className="model-header">
                            <div className="expertprofile__right-title">
                                {duplicateCount?.length &&
                                    <div className="duplicate-text" onClick={() => {
                                        setOpenModel(false);
                                        handleDuplicateProfilesClick(duplicateCount);
                                    }}>View Duplicates ({duplicateCount.length})</div>
                                }
                                <img className="close-icon" src={CrossIcon} alt="close" onClick={() => {
                                    setOpenModel(false);
                                    closePopupHandler();
                                }} />
                            </div>
                            <div className="expertprofile__title">Profile</div>
                            <div className="expertprofile__heading">
                                <div className="line1">
                                    <div><span>{data?.expertName}
                                        {data.recommended && <Image src={RecommendIcon} alt='recommended icon' />}
                                    </span></div>
                                    <div className="action-btns">
                                        <button className="decline-btn" onClick={() => { profileActionHandler('decline') }} disabled={data.profileStatus == "Declined"}><Image src={MinusIcon} alt='remove icon' />Decline Profile</button>
                                        <button className="shortlist-btn" onClick={() => { profileActionHandler('shortlist') }}>
                                            {data.shortlisted ?
                                                <><Image src={FilledStarIcon} alt='star icon' /> Shortlisted Profile</>
                                                : <><Image src={StarIcon} alt='star icon' /> Shortlist Profile </>
                                            }
                                        </button>
                                    </div>
                                </div>
                                <div className="line2">
                                    <div>{data?.relevantExperience.title} - {data?.relevantExperience.employerName} | <span className="expert-location"><Image src={PinIcon} alt='location pin' />{data?.country}</span></div>
                                </div>
                                <div className="line3">
                                    <div className="profile-icons"><Image src={UserIcon} alt='user icon' /></div>
                                    <span>External Network :  {data?.networkProvider}</span>
                                    {/* <div className={`mini-dropdown ${isNetworkPopupOpen && 'field-dropdown'}`}>  kirti: may required in future
                                <input type='text' readOnly value={selectedNetwork}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setIsNetworkPopupOpen(!isNetworkPopupOpen);
                                    }}
                                ></input>
                                <img className="arrow-img" src={DownArrow} alt="DownArrow"
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setIsNetworkPopupOpen(!isNetworkPopupOpen);
                                    }} />
                                {
                                    isNetworkPopupOpen &&
                                    <div className="mini-dropdown-popup" ref={networkPopupRef}>
                                        <ul>
                                            {externalNetworkData?.map((option, popupIndex) => (
                                                <li key={popupIndex} onClick={(event) => { setSelectedNetwork(option); setIsNetworkPopupOpen(false); }} className={selectedNetwork == option ? 'selected-list-item' : ''}>{option}</li>
                                            ))}
                                        </ul>
                                    </div>
                                }
                            </div> */}
                                    {data.rate && <><div className="profile-icons"><span>$</span></div>
                                        <span>{data.rate.costPerHour}/hr, {data.rate.costPer30Mins}/30 min <span className="normal-text">({data.rate.rateType})</span></span>
                                    </>}
                                    <div className="profile-icons"><Image src={CalIcon} alt='calender Icon' /></div>
                                    <span>Profile Sent On : {moment(data.receivedTimestamp).format('ddd, DD MMM YYYY LT')} GMT</span>
                                </div>
                            </div>
                        </div>
                        <div className="expertprofile__details model-body">
                            <span>About Expert</span>
                            <p>
                                {data.biography}
                            </p>
                            {data.expertTypes?.length > 0 && <>
                                <span>Expert Types / Modules</span>
                                <div className="expert-type-list">
                                    {data.expertTypes.map((type, index) => (
                                        <div key={type} className="expert-type"><span>{type}</span></div>
                                    ))}
                                </div>
                            </>
                            }
                            <span>Relevant work experience </span>
                            <DataDisplayGrid columns={expColumns} data={expData} />
                            {data.screeningQuestions?.length > 0 && <>
                                <span>Responses to screening questions</span>
                                {data.screeningQuestions.map((set, index) => (
                                    <div className='screening-ques' key={set.question}>
                                        <div className="qus-ans-block">
                                            <div className="qus-icon"><span>Q</span></div>
                                            <div className="question"><span>{set.question}</span></div>
                                        </div>
                                        <div className="qus-ans-block">
                                            <div className="qus-icon ans-icon"><span>A</span></div>
                                            <div className="answer"><span>{set.answer}</span></div>
                                        </div>
                                    </div>
                                ))}
                            </>
                            }
                            <span>Employment History</span>
                            <DataDisplayGrid columns={empColumns} data={empData} />
                        </div>
                        <div className="expertprofile__action-container model-footer">
                            <button className='outline-btn' onClick={(event) => { setOpenModel(false); closePopupHandler(); }}>
                                <Image src={SlackIcon} className='edit--icon' alt="slack" />
                                <span className='disable-btn'>Request more information</span>
                            </button>
                            {data?.status != 'Approved' ?
                                <Tooltip anchorSelect=".tooltip-request-call" place="bottom-start" effect='solid' multiline={true} content="Calls cannot be requested at this time as the communication template for this external network has not yet been approved. Please check back later or contact the network for more details." />
                                : <Tooltip anchorSelect=".tooltip-request-call" place="bottom-start" effect='solid' multiline={true} content="Click to propose or select from available time slots suggested by experts." />}
                            <button className="default-button tooltip-request-call" onClick={() => { setOpenModel(false); closePopupHandler(); openScheduler(); }} >
                                {/* disabled={data?.status != 'Approved'} */}
                                <Image src={ClockIcon} className='edit--icon' alt="clock" />
                                <span>Request Call</span>
                            </button>
                            <Tooltip anchorSelect=".info-icon" place="bottom-start" effect='solid' multiline={true}>Click “Request Call” to propose or select from available time slots suggested by experts.</Tooltip>
                            <Image className='edit--icon info-icon' src={MoreInfoIcon} alt="info icon" />
                        </div>
                    </div>
                </div>
                <div>
                    <button className='carousel-btn' onClick={(event) => { carouselActionClicked('next', profileSelected); }} disabled={lastRecord}>
                        <span className='disable-btn'>Next</span>
                        <Image src={NextArrow} className='edit--icon' alt="next icon" />
                    </button>
                </div>
            </Modal>
        </>
    );
};